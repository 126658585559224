:root {
  --common-font-Lt: 'Lato', sans-serif;
  --blue: #06356a;
}

html {
  overflow-x: hidden;
}

* {
  font-family: var(--common-font-Lt);
}

a {
  color: inherit;
}

a:hover {
  color: inherit;
}

button,
input,
select,
textarea {
  font-family: var(--common-font-Lt);
}

.header-main {
  text-align: center;
  padding: 17px 0;
}

.home-crousal img {
  max-width: 300px;
  margin: 26px 0;
}

ul.control-dots {
  bottom: -9px !important;
}

ul.thumbs.animated {
  justify-content: center;
  display: flex;
}

ul.thumbs.animated li {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.thumbs.animated li img {
  max-width: 50px !important;
}

.locat_test {
  position: absolute;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 1px #ddd;
  top: 23px;
  min-width: 100px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 0;
  display: none;
}

.howar:hover .locat_test {
  display: block;
}

.howar {
  position: relative;
}

.locat_test li a {
  color: #000;
  font-size: 16px;
}

.howar span {
  color: #fff;
  cursor: pointer;
}

.locals {
  float: right;
}

ul.navbar-nav.mr-auto li a input {
  background: #fff !important;
  border: 0 !important;
  border-radius: 4px !important;
  margin: 0 3px !important;
}

.locat_test li {
  width: 100%;
  text-align: left;
}

.locat_test li a {
  margin: 0 !important;
}

ul.navbar-nav.mr-auto {
  width: 100% !important;
  text-align: right !important;
  float: right !important;
  margin-left: auto !important;
  justify-content: flex-end !important;
}

.logo-img {
  margin-right: 20px;
}

ul.navbar-nav.mr-auto li:nth-last-c.container {
  max-width: 1400px !important;
}

.footer-second {
  text-align: center;
  padding: 20px 0;
}

footer-second i {
  margin: 0 4px;
}

.product_cat h3 {
  width: 100%;
  float: left;
  text-align: center;
  padding: 12px 0 40px;
  color: #000;
}

.product_cat .img img {
  height: auto;
}

.product_cat .img {
  min-height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.product_cat .categories a {
  color: #000;
  font-size: 18px;
  text-transform: capitalize;
}

.product_cat h4 {
  width: 100%;
  float: left;
  text-align: center;
  padding: 12px 0 40px;
  color: #000;
}

.product_cat {
  padding: 100px 0 80px;
  margin: 0;
}

p {
  font-size: 18px;
  line-height: 30px;
}

.product_cat h2 {
  margin-bottom: 27px;
}

body h4 {
  font-size: 50px;
  font-weight: 700;
  text-align: center;
}

.product_cat .img img {
  position: relative;
  left: 110px;
  top: -70px;
}

.product_cat .categories {
  text-align: center;
  position: relative;
  left: 110px;
  top: -70px;
}

.product_cat .categories a {
  color: #06356a;
  font-size: 33px;
}

.custom-container {
  padding: 0 110px;
  width: 100%;
}

.home-crousal img {
  width: 100%;
  max-width: 100%;
}

.banner-sec {
  padding: 310px 0;
  background-size: 100% 100%;
  background-position: top 60px center;
}

.banner-sec .row {
  align-items: center;
}

.banner-left-content h4 {
  color: #fff;
  text-transform: uppercase;
  font-size: 80px;
  font-weight: 600;
}

body .navbar .custom-container {
  display: flex;
  align-items: center;
}

.banner-left-content p span {
  color: #fff;
  text-transform: uppercase;
  font-size: 31px;
  margin-right: 40px;
}

.banner-left-content p {
  margin-top: 20px;
}

.banner-right-content h3 {
  text-align: center;
  color: #fff;
  font-size: 57px;
  font-weight: 600;
}

.banner-right-content p {
  text-align: center;
  color: #fff;
}

.banner-right-content .btns {
  justify-content: center;
  display: flex;
}

.banner-right-content .btns a:first-child {
  background: #06356a;
  color: #fff;
  padding: 0 18px;
  height: auto;
  line-height: 32px;
  font-size: 17px;
}

.banner-right-content .btns a:last-child {
  border: 1px solid #fff;
  color: #fff;
  padding: 3px 18px;
  height: auto;
  line-height: 30px;
  font-size: 17px;
  margin-left: 12px;
}

body .navbar .logo-img {
  font-size: 30px;
  font-weight: 700;
  color: #3365a0;
}

.signupform {
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 35px;
  width: 50%;
}

.container {
  max-width: 1400px;
}

.left-side {
  width: 100%;
  float: left;
  border: 1px solid #ddd;
  border-top: 4px solid #06356a;
  border-radius: 6px;
  padding: 0;
  margin-top: -19px;
}

.left-side ul {
  margin: 0;
  padding: 0;
}

.left-side ul li {
  list-style: none;
  border-bottom: 1px solid #ddd;
  padding: 24px 30px;
  position: relative;
}

.left-side ul li a {
  color: #fff;
  text-transform: uppercase;
}

.left-side ul li::before {
  width: 8px;
  height: 8px;
  border: 2px solid;
  content: "";
  position: absolute;
  border-top: 0;
  border-left: 0;
  transform: rotate(-45deg);
  left: 11px;
  top: 31px;
}

.left-side ul li:last-child {
  border: 0;
}

.right-side .categories img {
  max-width: 190px;
  max-height: 122px;
}

.right-side .categories h1.title {
  color: #000;
  font-size: 18px;
  margin-top: 8px;
}

.right-side .categories h4.title {
  font-size: 19px;
  font-weight: 400;
  margin: 0 0 10px;
  padding: 0;
}

.right-side .categories select {
  width: 100%;
  float: left;
  max-width: 200px;
  clear: both;
  display: table;
  margin: 0 auto;
  float: none;
  height: 34px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 5px;
  padding: 0 10px;
  color: #353535;
}

.add-cart {
  display: flex;
  justify-content: center;
}

.add-cart input[type="number"] {
  border: 1px solid #ddd;
  text-align: center;
  margin-right: 10px;
  border-radius: 5px;
  color: #252525;
}

.add-cart button,
.variant_exist a h1 {
  background: #06356a;
  border: 0;
  text-transform: uppercase;
  color: #fff;
  padding: 6px 25px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.right-side .categories {
  position: static;
  margin-bottom: 40px;
  box-shadow: 0 0 10px 1px #ddd;
  padding: 23px 0 35px;
  border-radius: 9px;
  min-height: 442px;
}

.right-side h4 {
  padding: 0;
  font-size: 36px;
}

ul.pagination {
  justify-content: center;
  margin-bottom: 25px;
}

ul.pagination li {
  margin: 0 7px;
}

.main_all_category_section_class .search input {
  color: #000;
}

ul.pagination li a {
  outline: none;
}

ul.pagination li.active {
  background: #06356a;
  font-weight: bold;
  border-color: #06356a;
}

.product_cat.home-pro-main .row {
  width: 31.5%;
  float: left;
  border: 1px solid #ddd;
  margin: 0 10px 37px;
  padding: 16px 10px;
  border-radius: 10px;
}

.product_cat.home-pro-main .row .col-md-6 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.product_cat.home-pro-main img {
  position: static;
  max-width: 200px;
  min-height: 210px;
  object-fit: cover;
}

.product_cat.home-pro-main .categories {
  position: static;
}

.footer-second {
  width: 100%;
  float: left;
}

.product_cat.home-pro-main .row .col-md-6 p {
  text-align: center;
  margin-bottom: 1px;
}

.product_cat.home-pro-main .row .col-md-6 a {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  float: left;
}

.left-side .search {
  padding: 10px 14px;
  border-bottom: 1px solid #ddd;
}

.left-side .search input[type="text"] {
  width: 100%;
  float: left;
  height: 37px;
  border: 1px solid #ddd;
  padding: 0 11px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.left-side .search button {
  width: 100%;
  height: 37px;
  border-radius: 5px;
  background: #06356a;
  border: 0;
  color: #fff;
  text-transform: uppercase;
}

.left-side ul li.active {
  background: #fff;
}

.left-side ul li.active a {
  color: #06356a;
}

.left-side ul li.active::before {
  border-color: #00356a;
}

.cst-categorie-sec {
  width: 100%;
  float: left;
}

.product-sec-cstm {
  padding: 85px 0;
}

.product-sec-cstm h1 {
  color: #000;
  font-size: 45px;
  font-weight: 400;
}

.main-qty-sec {
  margin: 0 -15px 15px;
  display: flex;
}

.main-qty-sec .box {
  padding: 0 15px;
  max-width: 50%;
  flex: 0 0 50%;
  -ms-flex: 0 0 50%;
}

.product-sec-cstm select {
  width: 100%;
  height: 45px;
  padding: 0 15px;
  border: 1px solid #ddd;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  margin: 0 !important;
}

.select {
  position: relative;
}

.select::after {
  width: 8px;
  height: 8px;
  border: 2px solid;
  content: "";
  position: absolute;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) translateY(-50%);
  right: 15px;
  top: 70%;
  margin-top: -12px;
}

.mainorder-detail-sec.main_profile_section_class .profile-page-main .container_of_slider button {
  background: transparent !important;
  margin: 0 10px 0 0;
}

.product-sec-cstm h3 {
  font-size: 30px;
  font-weight: 300;
}

.main-qty-sec .box:last-child {
  display: flex;
  align-items: center;
}

.main-qty-sec .box:last-child span {
  max-width: 42%;
  flex: 0 0 42%;
  -ms-flex: 0 0 42%;
}

.main-qty-sec .box:last-child #qty {
  max-width: 70%;
  flex: 0 0 70%;
  -ms-flex: 0 0 70%;
}

.main-qty-sec .box #qty {
  position: relative;
  border: 1px solid var(--blue);
  align-items: center;
}

.main-qty-sec .box #qty input {
  width: 20%;
  border: 0;
  height: 37px;
  text-align: center;
  background: #fff;
}

.Cart_product .row {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 15px;
  align-items: center;
}

.main-qty-sec .box #qty button {
  /* position: absolute; */
  top: 0;
  bottom: 0;
  width: 22px;
  height: 37px;
  background: transparent;
  color: #000;
  border: 0;
  padding: 0;
}

.product-slider-text b {
  font-weight: 500;
}


:focus {
  outline: none;
}

.select-variant-dropdown {
  margin: 0 0 15px;
}

.select-variant-dropdown p {
  margin: 0;
  font-size: 15px;
}

.Product_details {
  width: 80%;
}

.btn-sec {
  margin: 0 -15px;
}

.btn-sec .Add_to_cart,
.btn-sec .Add_to_wishList {
  width: 50%;
  float: left;
  padding: 0 15px;
}

.btn-sec button {
  background: #06356a;
  border: 0;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  margin: 0;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.btn-sec .Add_to_wishList button {
  background-color: transparent;
  border: 1px solid #06356a;
  color: #06356a;
}

.left-side {
  background: #00356a;
  color: #fff;
}

.Images_product img {
  width: 100%;
  height: auto;
}

.cart-sec {
  padding: 160px 0 85px;
}

.cart-sec .container {
  margin: 0 auto;
}

.cart-sec br {
  display: none;
}

.cart-sec h1.title {
  text-align: center;
  margin-bottom: 0;
}

.cart-heading {
  margin: 20px 0 35px;
}

.cart-sec .col-md-4 {
  border: 1px solid #ddd;
  border-right: 0;
}

.cart-sec .col-md-4>div {
  padding: 15px 0;
  width: 100%;
  float: left;
}

.cart-product-qty-del .main-qty-sec .box {
  max-width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  padding: 0;
}

.cart-product-qty-del .main-qty-sec .box:last-child #qty {
  max-width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
}

.cart-sec .row .col-md-4:first-child {
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
  max-width: 10%;
}

.cart-sec .row .col-md-4:nth-child(2) {
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  max-width: 70%;
}

.cart-sec .row .col-md-4:last-child {
  border-right: 1px solid #ddd;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.cart-image img {
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.cart-product-details br {
  display: block;
}

.cart-product-qty-del .main-qty-sec {
  margin: 0;
  display: flex;
  width: 70%;
  float: left;
}

.checkout-btn .row .col-md-4:first-child {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.checkout-btn .row .col-md-4:last-child {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  float: right;
}

.cart-product-qty-del button {
  background: transparent !important;
  color: red;
  padding: 0;
}

.pro-cart-table .Add_to_cart button {
  background: #06356a;
  color: #fff;
  text-transform: uppercase;
  border: 0;
  font-size: 15px;
  padding: 8px 10px;
  border-radius: 5px;
  margin-right: 13px;
}

.checkout-btn {
  width: 50%;
  float: right;
}

.checkout-btn .col-md-4 {
  border: 0 !important;
  text-align: right;
  padding: 0;
}

.checkout-btn .col-md-4 br {
  display: block;
}

.checkout-btn .col-md-4 button {
  background: #06356a;
  border: 0;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  margin: 0;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  float: right;
}

.checkout-btn ul {
  padding: 0;
  margin: 40px 0;
  list-style: none;
  width: 50%;
  float: right;
}

.checkout-btn ul li {
  text-align: right;
  padding: 0 0 15px;
  margin: 0 0 15px;
  border-bottom: 1px solid #ddd;
}

.checkout-btn ul li:last-child {
  border-bottom: 0;
  padding: 0;
  margin: 0;
}

.checkout-btn ul li strong {
  float: left;
  font-weight: 600;
  text-transform: uppercase;
}

.thumb-slider img {
  width: 120px;
  height: 120px;
  padding: 10px;
  border: 1px solid #ddd;
  margin: 0 auto;
}

.wishlistdata_product .btn-sec .Add_to_cart {
  width: 70%;
}

.cart-sec .select select {
  height: 37px;
  padding: 0 7px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 5px;
}

.cart-sec .select::after {
  display: none;
}

.Cart_product div#qty {
  max-width: 100% !important;
  flex: 0 0 100% !important;
  -ms-flex: 0 0 70%;
}

.pro-name {
  text-transform: capitalize;
}

.Cart_product .col-md-4 {
  border: 0 !important;
}

.pro-cart-table {
  display: flex;
  align-items: center;
}

.container.order-detail-page h3.title {
  text-align: center;
  text-transform: uppercase;
  font-size: 38px;
  margin: 50px 0;
}


.backbutton {
  float: left;
  width: 100%;
}

.order-detail-page .backbutton button.back-button {
  margin: 20px 0;
  text-align: center;
  padding: 10px 50px;
  max-width: 100px;
  display: inline-flex;
  justify-content: center;
}

.container.checkout-main h1.title {
  text-align: center;
}

.edit-checkout-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.edit-checkout-btn button {
  margin-left: 10px;
  background: #06356a;
  border: 0;
}

.radio-bnt {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 17px;
  border-radius: 6px;
  background: #fff;
}

.radio-bnt input {
  margin-right: 13px;
}

.checkout-addrees h5 {
  margin: 0;
  text-transform: capitalize;
  font-size: 16px;
}

span.Total {
  margin-left: auto;
  width: 100%;
  text-align: right;
  padding-right: 16px;
  margin-bottom: 10px;
}

span.amount_paid {
  margin-left: auto;
  width: 100%;
  text-align: right;
  padding-right: 16px;
}

.container.order-detail-page h1 {
  text-align: center;
  text-transform: capitalize;
  font-size: 26px;
}

.order-sec {
  border: 1px solid #ddd;
  padding: 21px;
  max-width: 400px;
  border-radius: 6px;
  border-top: 4px solid #06356a;
}

.apply-coupn .row {
  border: 0 !important;
}

.apply-coupn .row form.signupform {
  position: static;
  transform: translate(0);
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.apply-coupn button.btn.btn-primary {
  margin: 0 auto;
  display: table;
}

.apply-coupn {
  border: 1px solid #ddd;
  padding: 7px;
  max-width: 500px;
  border-radius: 0px;
  border-top: 4px solid #06356a;
  display: table;
  width: 100%;
  margin: 0 auto 40px;
}

.apply-coupn label {
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
}

.apply-coupn button.btn.btn-primary:last-child {
  margin-top: 10px;
}

.apply-coupn .row button.btn.btn-primary:last-child {
  margin-top: 0;
}

span.Discount {
  width: 100%;
  float: left;
  text-align: right;
}

.apply-coupn button {
  background: #06356a;
  border: 0;
}

.apply-coupn .row form.signupform button {
  margin-left: 8px;
}

.container.checkout-main form button {
  background: #06356a;
  border: 0;
  margin-top: 10px;
}

.container.checkout-main form label.form-label {
  width: 100%;
  float: left;
  margin: 10px 0 4px;
}

.container.checkout-main button {
  background: #06356a;
  color: #fff;
  text-transform: uppercase;
  border: 0;
  font-size: 15px;
  padding: 8px 15px;
  border-radius: 40px;
  margin: 13px 0 19px;
  height: 45px;
  border: 2px solid #00356a;
  transition: 0.3s ease all;
}

.container.checkout-main h4 {
  text-align: center;
  background: #06356a;
  color: #fff;
  padding: 15px;
  border-radius: 0;
  font-size: 14px;
  font-weight: 600;
}

.thnkyou-page {
  max-width: 640px;
  margin: 33px auto 80px;
  box-shadow: 0 0 10px 1px #ddd;
  padding: 15px;
  background: #fff;
  text-align: center;
}

.thnkyou-page h3 {
  font-size: 16px;
}

.Cart_product {
  background: #fff;
  padding: 10px 20px;
  margin-bottom: 0;
}


@media (max-width: 767px) {
  .Cart_product {
    margin-bottom: 30px;
    /* min-width: 290vw; */
  }
}

.thn-lft,
.thn-rgt {
  border: 1px solid #ddd;
  padding: 20px;
  background: #fff;
  margin-bottom: 30px;

}

.add-cart .main-qty-sec .box {
  max-width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
}

.add-cart .main-qty-sec .box div#qty button {
  border-radius: 0;
  color: #000 !important;
}

.add-cart .main-qty-sec {
  width: 50%;
  max-width: 50%;
  flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  padding-right: 9px;
  float: left;
}

.add-cart .main-qty-sec .box div#qty {
  max-width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
}

form.signupform.main-sign-frm {
  position: static;
  transform: translate(0);
  justify-content: space-between;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.03);
  margin-bottom: 25px;
  border-radius: 0px;
  padding: 15px;
}

form.signupform.main-sign-frm .Custom_filter input[type="tel"],
form.signupform.main-sign-frm .Custom_filter input[type="text"] {
  max-width: 100px;
  height: 40px;
  padding: 0 0px;
  line-height: 40px;
  border: 0 none;
  border-bottom: 1px solid #ddd;
  border-radius: 6px;
  background: #FFF;
}

form.signupform.main-sign-frm .Custom_filter input[type="text"],
form.signupform.main-sign-frm .Custom_filter input[type="tel"] {
  border: 1px solid #ddd;
  padding: 0 10px;
}

form.signupform.main-sign-frm .MuiFormControl-root {
  background: none;
}

form.signupform.main-sign-frm h1 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.sign-up-bnt button,
form.signupform.main-sign-frm button.btn.btn-primary {
  background: #06356a;
  margin-right: 8px;
}

.locat_test li a {
  color: #000;
}

form.signupform.main-sign-frm a {
  color: #06356a;
  text-transform: capitalize;
  text-decoration: none;
  font-family: var(--common-font);
}

.signupform span {
  color: red;
  font-size: 13px;
}

nav.navbar.navbar-expand-lg.navbar-dark.bg-primary {
  background: #06356a !important;
}

.locat_test li input[type="button"] {
  background: #06356a;
  color: #fff;
  border: 0;
  padding: 1px 13px 3px;
  font-size: 16px;
  border-radius: 4px;
  margin-top: 4px;
}

.profile-page-main h3 {
  text-align: center;
}

.container.checkout-main button.dlt {
  margin: 0;
  font-size: 15px;
  margin-left: auto;
  float: right;
  background: transparent;
  padding: 5px 10px 0;
  color: red;
}

.locat_test {
  z-index: 9;
}

ul.navbar-nav.mr-auto li a input {
  color: #000;
  text-transform: capitalize;
  background: #06356a;
  color: #fff;
  border: 0;
  padding: 1px 13px 3px;
  font-size: 16px;
  border-radius: 4px;
  margin-top: 0;
  width: 100%;
  margin: 0;
}

.container.checkout-main .mb-3 span,
.container.checkout-main .mb-6 span,
.profile-page-main form span {
  color: red;
  font-size: 13px;
}

.profile-page-main {
  width: 100%;
  float: left;
  padding-bottom: 60px;
  margin-top: 110px;
}

.locat_test li a {
  color: #000;
}

.pro-fil-user {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  margin-top: 69px;
}

.profile-page-main button {
  margin: 18px auto 0;
  display: table;
  background: #06356a;
  border: 0;
  color: #fff;
}

.profile-page-main form .row {
  margin: 0;
}

.cart-sec h3.title,
.cart-sec h4 {
  text-align: center;
}

span.Discount,
span.Total,
span.amount_paid {
  display: flex;
  justify-content: flex-end;
}

span.Discount strong,
span.Total strong,
span.amount_paid strong {
  padding-right: 10px;
}

.howar i,
ul.navbar-nav.mr-auto li a i {
  font-size: 20px;
}

.profile-page-main form {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
}

body .locat_test li a {
  color: #000;
}

.Add_to_cart.out-of-stock button {
  cursor: not-allowed;
  opacity: 0.5;
  border-radius: 40px;
}

.locat_test li {
  padding: 1px 10px !important;
}

ul.navbar-nav.mr-auto li:nth-last-child(3) {
  /* display: none; */
  padding-left: 0;
}

.container.order-detail-page .col-md-2 a {
  color: #06356a;
}

p.title.pro-price {
  display: flex;
  justify-content: center;
}

p.title.pro-price b {
  padding-right: 6px;
}

.row.addres-page {
  margin-top: 110px;
  float: left;
  width: 100%;
}

.row.addres-page .radio-bnt {
  position: relative;
}

.row.addres-page button.edit-address {
  float: right;
  margin-left: auto;
  margin-right: 30px;
  background: #06356a;
  border: 1px solid #06356a;
  color: #fff;
  padding: 10px 15px;
  line-height: 1;
  border-radius: 5px;
}

.inner-div {
  width: 100%;
  float: left;
  min-height: 900px;
}

.Product_details p {
  display: flex;
}

.Product_details p b {
  padding-right: 8px;
}

.order-detail-sec .row {
  padding-bottom: 0;
  border: 0;
  margin: 0;
}

.order-detail-sec .col-md-4,
.order-detail-sec .col-md-8 {
  border: 1px solid #ddd !important;
  padding: 15px;
}

.order-detail-sec .col-md-4 {
  border-left: 0 !important;
}

.cstm-tittle,
.order-detail-sec .tittle {
  padding: 10px;
  background-color: #06356a;
  border-color: #000;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  margin: 0 0 15px;
  font-size: 18px;
}

.order-cstm h4.cstm-tittle {
  margin-top: 40px;
}

.order-cstm .thn-lft {
  margin-top: 40px;
  border-color: #e3e3e3;
  background-color: #f3f3f3;
  padding: 0 0 40px;
  margin-bottom: 0;
  overflow: hidden;
}

.order-cstm .thn-lft h4 {
  padding: 10px;
  background-color: #06356a;
  border-color: #000;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  border: 10px;
  font-size: 15px;
  font-weight: bold;
  padding: 15px;
  border-radius: 0;
}

.order-cstm .thn-lft div {
  padding: 0 15px;
}

button.re-order {
  background: #06356a;
  border: 1px solid #06356a;
  color: #fff;
  padding: 15px 40px;
  font-size: 18px;
  line-height: 1;
  display: table;
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-radius: 6px;
}

.mini-cart-sec {
  position: fixed;
  right: 0;
  top: 0;
  background: #ffff;
  width: 376px;
  box-shadow: 0 0 10px 1px #ddd;
  height: calc(100vh);
}

.mini-cart-sec .container {
  padding: 0;
  background: #fff;
  height: 100%;
}

.mini-cart-sec .container h1.title {
  background: #06356a;
  color: #fff;
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px 0;
}

.mini-cart-sec .container .min-cat-uppper {
  display: flex;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  padding: 10px 15px 20px;
  position: relative;
  align-items: center;
}

.mini-cart-sec .container .mini-cart .cart-image {
  width: 30%;
}

.mini-cart-sec .container .mini-cart .mini-cart-des {
  width: 70%;
  float: left;
  padding-left: 10px;
  text-align: left;
}

.mini-cart-sec .container button.btn.btn-primary {
  margin: 0 auto;
  display: table;
  float: none;
}

.mini-cart {
  position: relative;
}

.mini-cart-sc {
  overflow-y: auto;
  height: calc(100vh - 240px);
}

.mini-cart-sec .container .mini-cart .cart-image img {
  width: 100%;
}

.mini-cart-sec .container .mini-cart .cart-product-qty-del {
  position: absolute;
  right: 0;
  top: 0;
  /* left: 0;
  margin: 0 auto;
  text-align: center; */
}

.mini-cart-sec .container .mini-cart .cart-product-qty-del button.dlt {
  background: transparent;
  color: #000;
  font-size: 13px;
  width: 100%;
  height: auto;
}

.mini-cart-sec .cart-heading {
  margin-bottom: 0;
  margin-top: 0;
}

.mini-cart-sec .container .mini-cart .mini-cart-des .cart-product-details {
  font-weight: 600;
  margin-bottom: 6px;
  font-size: 15px;
}

.mini-cart-sec .container .mini-cart .mini-cart-des .main-qty-sec {
  justify-content: flex-start;
  margin-bottom: 5px;
  margin-left: 0;
}

.mini-cart-sec .container .mini-cart .mini-cart-des .main-qty-sec button {
  color: var(--blue);
  border-radius: 0;
  background: #fff;
  padding: 10px;
  width: 50px;
  height: 40px;
  font-weight: 800;
  border: 1px solid var(--blue);
}

.mini-cart-sec .container .mini-cart:nth-child(2n) {
  background: #ececec;
}

.mini-cart-sec .checkout-btn {
  width: 100%;
  float: left;
  position: absolute;
  padding: 0 15px;
  bottom: 0;
  left: 0;
}

.mini-cart-sec .checkout-btn .row {
  margin: 0;
}

.mini-cart-sec .checkout-btn .row ul {
  width: 100%;
}

.mini-root-div {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #00356a;
  padding: 13px;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
}

.mini-root-div .fa.fa-shopping-cart {
  color: #fff;
  font-size: 24px;
}

.fa.fa-shopping-cart {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.mini-root-div .fa.fa-shopping-cart::before {
  content: "\F07A";
  color: #fff;
  font-size: 20px;
}

.mini-root-div span {
  position: absolute;
  top: 8px;
  right: 4px;
  background: #f00;
  color: #fff;
  font-size: 14px;
  width: 17px;
  height: 17px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container .col-md-9.dashboard-main {
  margin: auto;
  width: 75%;
  padding: 10px;
  text-align: center;
}

.col-md-3 {
  margin-top: 10%;
}


.addres-page.row .col-md-3 {
  margin: auto;
}

.profile-page-main .container .row .col-md-3 {
  margin-top: auto;
}

.inner-div .container .row.addres-page .col-md-3 {
  margin: auto;
}

.inner-div .container .row.addres-page .col-md-9 {
  margin: auto;
}

.mainorder-detail-sec {
  padding: 92px 0 85px;
  width: 100%;
  float: left;
  margin-top: 100px;
  margin-top: 40px;
}

.mainorder-detail-sec .container.order-detail-page h3.title,
.mainorder-detail-sec h3.title {
  margin: 0 0 25px;
  text-align: left;
  font-size: 24px;
  padding: 15px;
  background: #06356a;
  color: #fff;
}

/* .mainorder-detail-sec .container.order-detail-page {
  padding: 0 0 20px;
  margin: 0;  
}*/

.mainorder-detail-sec .container.order-detail-page .export-to-xls button {
  margin: 0px;
}

.mainorder-detail-sec .container.order-detail-page button {
  border: 2px solid #06356a;
  background: #06356a;
  color: #fff;
  padding: 10px 30px;
  font-size: 16px;
  border-radius: 50px;
  height: unset;
  line-height: 1;
}

.mainorder-detail-sec .container.order-detail-page button.MuiIconButton-sizeSmall {
  padding: 7px 25px !important;
  font-family: var(--common-font-Lt);
}

.mainorder-detail-sec .container.order-detail-page table button[type="button"] {

  padding: 3px 10px;
}

.mainorder-detail-sec .container.order-detail-page button:hover,
.main-invitaion a.btn.btn-primary:hover {
  background: #fff;
  color: #00356a;
}

/* .mainorder-detail-sec .container.order-detail-page button:hover {
  color: #fff !important;
  background-color: #00356a !important;
} */

.mainorder-detail-sec .container.order-detail-page .row {
  align-items: flex-start;
}

.mainorder-detail-sec .profile-page-main {
  margin: 0;
}

.mainorder-detail-sec .profile-page-main .container .row .col-md-3,
.mainorder-detail-sec .pro-fil-user {
  margin-top: 0;
}

.mainorder-detail-sec .container .col-md-9.dashboard-main {
  margin: 0;
  padding: 0 15px;
}

.mainorder-detail-sec .container .row.addres-page .col-md-3 {
  margin: 0 !important;
}

.mainorder-detail-sec .row.addres-page {
  margin-top: 0;
}

ul.pagination li a {
  padding: 3px 11px;
  display: block;
}

ul.pagination li.active,
ul.pagination li.active a {
  color: #fff;
}

ul.pagination .next.disabled,
ul.pagination .previous.disabled {
  display: none;
}

.mainorder-detail-sec .main-qty-sec {
  margin: 0;
}

.mainorder-detail-sec .main-qty-sec .box {
  margin: 0 auto;
  max-width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
}

.mainorder-detail-sec .Cart_product.order-detail-sec .row {
  padding-bottom: 0;
  border-bottom: 0;
}

.mainorder-detail-sec .col-md-4.qty-sec {
  padding: 7px;
  border-left: 0;
}

.mainorder-detail-sec .order-detail-sec .row .col-md-4:first-child {
  border-left: 1px solid #ddd !important;
}

.mainorder-detail-sec .backbutton {
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}

.skip-false button {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.sing-out .fa {
  cursor: pointer;
  color: #fff;
  font-size: 22px;
}

/* .mini-cart-sec .container .mini-cart .mini-cart-des .main-qty-sec {
  justify-content: flex-start;
} */

.mini-cart-sec .container .mini-cart .mini-cart-des .main-qty-sec .box {
  margin: 0;
  max-width: 70%;
  flex: 0 0 70%;
  -ms-flex: 0 0 70%;
  padding-left: 0;
}

.mini-cart-sec .select {
  width: 70%;
}

.mini-cart-sec .select::after {
  margin-top: -11px;
}

.mini-cart-sec select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid #ddd;
  margin: 0;
  height: 35px;
  padding: 0 15px;
  font-size: 14px;
}

.mini-cart-sec .Cart_product>h3.title {
  text-align: center;
  padding: 15px 0 0;
  font-size: 16px;
  color: #f00;
}

.cart-product-details .title {
  margin: 0;
}

.mini-cart-sec .container {
  margin: 0;
}

li.nav-item input[type="button"] {
  border: 1px solid #fff;
  color: #fff;
  background-color: transparent;
}

.container.order-detail-page .apply-coupn .row {
  padding: 0;
  margin-bottom: 0;
}

.mainorder-detail-sec .col-md-3 {
  margin-top: 0;
}

.row.addres-page button.add-address {
  background: #06356a;
  border: 2px solid #06356a;
  color: #fff;
  padding: 10px 15px;
  line-height: 1;
  transition: 0.3s ease all;
  float: right;
  margin-top: 15px;
}

.add-address-sec {
  width: 100%;
  float: left;
  margin-top: 25px;
}

ul.pagination {
  width: 100%;
  float: left;
}

.cstm-btns-sec {
  margin-top: 15px;
}

.cstm-btns-sec button {
  background: #06356a;
  border: 1px solid #06356a;
  color: #fff;
  padding: 10px 15px;
  line-height: 1;
  border-radius: 5px;
}

.cstm-btns-sec button:last-child {
  margin-left: 15px;
}

.select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.row.addres-page textarea {
  resize: none;
  height: 100px;
  padding: 15px;
  border-color: #ddd;
}

:focus {
  outline: none;
  box-shadow: none;
}

span.error {
  font-size: 12px;
  color: #f00;
  position: relative;
}

.field-class {
  margin-bottom: 14px;
}

.userdetails {
  display: table;
  margin: 20px auto;
  padding: 10px;
  border: 1px solid #ddd;
}

.userdetails strong {
  margin-right: 10px;
}

ul.navbar-nav.mr-auto svg {
  fill: #fff;
  cursor: pointer;
}

ul.navbar-nav.mr-auto .count,
ul.navbar-nav.mr-auto li {
  color: #fff;
}

.notification_bar {
  position: relative;
  padding: 0 5px;
}

.notification_bar ul {
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ddd;
  border-top: 4px solid #06356a;
  border-radius: 6px;
  background-color: #fff;
  width: 200px;
  margin-top: 35px;
  transition: 0.3s all linear;
  opacity: 0;
  visibility: hidden;
}

.notification_bar ul li {
  padding: 10px !important;
  margin: 0;
  border-bottom: 1px solid #ddd;
  color: #000 !important;
  text-align: left;
  font-size: 15px;
}

.notification_bar:hover ul {
  opacity: 1;
  visibility: visible;
  margin-top: 19px;
}

.cart-product-details .select {
  display: table;
  width: 80%;
}

.cart-product-details select {
  width: 100%;
}

.cart-product-details .select::after {
  width: 8px;
  height: 8px;
  border: 2px solid;
  content: "";
  position: absolute;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) translateY(-50%);
  right: 15px;
  top: 50%;
  margin-top: -10px;
  display: block;
}

.mini-cart-des .cart-product-details .select::after {
  top: 70%;
}

.pro-cart-table .Add_to_cart button {
  width: 130px;
}

.delete-autoship-order svg {
  fill: #f00;
  display: table;
  margin: 0 0 0 10px;
}

.search.selection.dropdown .menu {
  background-color: #fff;
  width: 100%;
  padding: 20px 0 0;
  border: 1px solid #ddd;
  border-top: 4px solid #06356a;
  border-radius: 0px;
  margin: 30px 0;
}

.search.selection.dropdown .menu .item {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  padding: 15px 20px;
  border-top: 1px solid #ddd;
  /* border-left: 1px solid #ddd; */
}

.search.selection.dropdown .menu .item:first-child,
.search.selection.dropdown .menu .item:nth-child(2) {
  border-top: 0;
}

.search.selection.dropdown .menu .item img {
  /* width: 60px; */
  height: 60px;
  object-fit: cover;
  padding: 5px;
  border: 1px solid var(--blue);
  margin-right: 15px;
}

.search.selection.dropdown .divider.text {
  margin: 40px 0;
}

.search.selection.dropdown .divider.text img {
  width: 120px;
  height: 120px;
  object-fit: contain;
  padding: 5px;
  border: 1px solid #ddd;
  margin-right: 15px;
  border-radius: 3px;
}

.search.selection.dropdown input[type="text"] {
  width: 50%;
  height: 45px;
  padding: 0 15px;
  border: 1px solid #ddd;
  margin: 15px 0 0;
  border-radius: 3px;
}

.search.selection.dropdown .divider.text {
  display: none;
}

.mainorder-detail-sec .delete svg {
  fill: #f00;
  cursor: pointer;
  margin: 10px 15px 0 0;
}

svg {
  cursor: pointer;
}

.changeadress-button {
  padding: 0px;
  margin: 10px 0 20px;
  width: 100%;
}

.changeadress-button button {
  float: right;
}

.edit svg {
  cursor: pointer;
  color: #06356a;
  width: 20px;
  height: 20px;
}

.skip-true button {
  text-transform: uppercase;
}

.row.addres-page button.add-address {
  margin-top: 0;
  margin-bottom: 15px;
}

.row.addres-page ul.pagination {
  margin: 15px 0 0;
}

.pro-name {
  font-size: 15px;
}

.row.addres-page button.btn.btn-primary+svg {
  float: right;
  margin-left: auto;
  margin-right: 30px;
  cursor: pointer;
  color: #06356a;
  width: 20px;
  height: 20px;
}

.container.checkout-main {
  margin: 100px auto 0;
}

.container.checkout-main h1.title {
  margin: 30px 0 15px;
  font-size: 26px;

}

.cart-product-details .title div {
  font-size: 14px;
  flex-wrap: wrap;
}

.smartship-label {
  flex: 0 0 100%;
}

.discount-maincls {
  display: flex;
}

.discount-maincls .smartship-label {
  flex: 0 0 auto;
}

.thnku-sec {
  margin-top: 160px;
}

.icon-thank {
  text-align: center;
}

.icon-thank svg {
  width: 70px;
}

.thnku-sec h2 {
  text-align: center;
}

.thnku-sec h4 {
  font-size: 26px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.header-menu ul li {
  position: relative;
  margin: 0 8px;
  padding: 0;
}

.header-menu ul li .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  border-top: 4px solid #06356a;
  display: block;
  margin-top: 20px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s all linear;
  padding: 0;
}

.header-menu ul li:hover .dropdown-menu {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

.header-menu ul li .dropdown-menu li {
  color: #000;
  padding: 10px !important;
  border-bottom: 1px solid #ddd;
}

.header-menu ul li .dropdown-menu li:last-child {
  border-bottom: 0;
}

.header-menu ul li,
.header-menu ul li i,
.header-menu ul li svg {
  cursor: pointer;
}

.header-menu ul li .icon-box {
  position: relative;
}

.header-menu ul li .icon-box span {
  position: absolute;
  top: -5px;
  right: -8px;
  width: 17px;
  height: 17px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.header-menu ul li .dropdown-menu li.btn-li {
  padding: 0 !important;
}

.header-menu ul li .dropdown-menu li.btn-li .btn {
  width: 100%;
  border: 0;
  background-color: #06356a;
  color: #fff;
  padding: 15px;
  line-height: 1;
}

.header-menu ul li:nth-child(3) {
  padding-right: 5px;
}

.view-icon svg {
  margin-left: 10px;
}

.header-menu ul li .dropdown-menu li {
  display: flex;
}

.header-menu ul li .dropdown-menu li img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 15px;
}

.changeadress-button button:last-child {
  margin-right: 15px;
}

.banner-sec {
  height: 710px;
}

.container.order-detail-page .disable button {
  background: #33c2eb8f;
  border: none;
  cursor: not-allowed;
}

.col-md-2.pro-name.action-text {
  text-align: center;
  float: none;
  min-width: 400px;
}


.order-cstm .radio-bnt {
  padding: 10px !important;
  margin: 0 15px 10px;
}

.mainorder-detail-sec .row.order-cstm h5 {
  font-size: 13px !important;
  font-weight: 500 !important;
  line-height: 22px;
}

.mainorder-detail-sec .order-cstm .thn-lft {
  margin-top: 0px;
  border-color: #ddd;
  background-color: #fff;
  padding: 0 0;
  margin-bottom: 0;
  border-radius: 0;
  box-shadow: none;
}

.mainorder-detail-sec .order-cstm .thn-lft h2 {
  margin: 0px;
  font-size: 15px;
  background: #06356a;
  color: #FFF;
  padding: 15px;
  line-height: 1;
  font-weight: 600;
}

button.add-product-autoshiporder {
  margin-bottom: 13px;
}

.title.pro-price {
  display: flex;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.title.pro-price b {
  padding-right: 6px;
}

.title.product-page-peice {
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: left;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 400;
  font-size: 30px;
}

.price {
  display: flex;
  margin-bottom: 10px;
}

.title.product-page-peice b {
  padding-right: 6px;
}

li.bell_dropdowon.dropdown.notifications-menu ul li:hover {
  background: #06356a;
  color: #fff;
  margin: 0;
}

.header-menu ul li .dropdown-menu {
  top: 46px;
}

.header-menu ul li ul.dropdown-menu.cart-notification {
  top: 40px;
  max-height: 225px;
  overflow-y: auto;
}

.button-top {
  text-align: right;
  float: right;
  width: 98%;
  margin-bottom: 33px;
}

.cart-notification {
  overflow-y: auto;
  min-height: 100px;
}

li.nav-item .cart-notification {
  overflow-y: auto;
  height: 283px;
  width: 330px !important;
}

.txt {
  font-weight: normal;
  font-size: 14px;
}

.forgot.password {
  padding: 1px 0 18px;
}

button.btn.btn-primary.sigup {
  float: right;
}

form.signupform.main-sign-frm h1 {
  font-size: 23px;
}

.container.order-detail-page h5 {
  background: transparent;
  color: #000;
}

.backbutton .back-button {
  background: #06356a;
  color: #fff;
  width: 89px;
  height: 37px;
  border-radius: 5px;
  font-weight: 700;
  border: 1px solid #06356a;
}

textarea.refund-message-box {
  width: 100%;
  height: 200px;
}

.cart-product-details {
  display: flex;
  align-items: center;
}

.cart-product-details-img {
  max-width: 100px;
  margin-right: 10px;
}

.cart-product-details-img img {
  width: 100%;
  object-fit: contain;
}

.cart-product-details .cart-product-details input[type="checkbox"] {
  margin-right: 5px;
}

.cart-product-details .col-md-4.qty-sec {
  padding: 0;
  border: 0 !important;
  margin-left: auto;
}

.cart-product-details .col-md-4.qty-sec .box {
  margin: 0 auto;
  max-width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  padding: 0;
}

.cart-product-details .col-md-4.qty-sec .box #qty button:last-child {
  right: 0;
  border-left: 1px solid #ddd;
}

.Cart_product.order-detail-sec .row {
  align-items: inherit;
}

.Cart_product.order-detail-sec {
  padding: 0;
}

.refund .thn-lft {
  background-color: transparent !important;
  border: 0;
}

.bundle-product {
  margin: 0 0 30px;
}

.bundle-product h1.title {
  text-transform: capitalize;
  margin: 30px 0 10px;
}

textarea {
  padding: 15px;
  border: 1px solid #ddd;
  resize: none;
}

.btn-sec-cstm {
  margin-top: 25px;
}

.checkout-addrees h5 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.container.order-detail-page .button-parrt {
  width: auto;
  float: left;
  position: relative;
}

.row.addres-page .add-address-sec+.row {
  align-items: inherit;
}

.row.addres-page button.btn.btn-primary {
  padding: 0;
}

div#treeWrapper {
  height: 500px !important;
  border: 1px solid #ddd;
}

.rd3t-tree-container {
  width: 100%;
  height: 100%;
  overflow: visible;
}

.rd3t-grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.rd3t-grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

/* Node */

/* Link */
.rd3t-link {
  fill: none;
  stroke: #000;
}

.rd3t-svg {
  overflow: visible;
  height: 100%;
  width: 101%;
  position: relative;
}

.node__root>circle {
  fill: red;
}

.rd3t-tree-container {
  overflow: scroll;
}

.rd3t-svg {
  overflow: visible;
  height: 100%;
  width: 101%;
  position: relative;
  margin: 17% auto 48% 112%;
}

ul.tabs {
  margin: 0;
  padding: 0 0 0 20px;
  text-align: left;
  list-style: none;
}

ul.tabs li {
  padding: 0;
  display: inline-block;
  vertical-align: top;
  max-width: inherit;
  flex: inherit;
  -ms-flex: inherit;
  width: auto;
}

ul.tabs li button {
  display: inline-block;
  background: transparent;
  color: #000;
  border: 1px solid #000;
  padding: 0 18px;
  height: auto;
  line-height: 32px;
  font-size: 17px;
  cursor: pointer;
  font-weight: 400;
  text-align: center;

  border-radius: 5px;
}

ul.tabs li.active button {
  background: #06356a;
  border: 1px solid #06356a;
  color: #fff;
}

.level-box {
  border: 1px solid #ddd;
  margin-top: -1px;
}

.Level {
  border: 1px solid #ddd;
}

.Level h2 {
  background: #00356a;
  margin: 0;
  color: #fff;
  text-align: center;
  padding: 15px 0;
  font-weight: 600;
  font-size: 26px;
  text-transform: uppercase;
}

.Level .col-md-6 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.Level .box {
  border-top: 1px solid #ddd;
  position: relative;
  padding: 15px;
  background: #f0f0f0;
}


.Level .box svg {
  width: 30px;
  height: 24px;
}

.Level .box .icons {
  position: absolute;
  top: 15px;
  right: 15px;
}

.Level .box .first_name,
.Level .box .user_rank {
  padding-left: 45px;
  text-align: left;
}

.Level .box .first_name h3,
.Level .box .user_rank h3 {
  font-size: 20px;
}

.Level .box .data {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  font-size: 14px;
}

.Level .box .data strong {
  width: 50%;
  float: right;
}

.Level .box svg:first-child {
  position: absolute;
  left: 15px;
  top: 15px;
}

.Level .box .icons svg {
  width: 24px;
  height: 25px;
  position: relative;
  top: auto;
  left: auto;
  display: inline-block;
  margin: 0 4px;
}

.Level .box .icons {
  position: absolute;
  top: 15px;
  right: 15px;
}

.lavel-box {
  padding: 0px 20px;
  border: 1px solid #ddd;
}

.lavel-box .row {
  margin: 0 -10px;
}

.lavel-box .row .col-md-3 {
  padding: 0 10px;
}

.Level .col-md-6 {
  padding: 0 10px;
}

.downline_users {
  padding-bottom: 80px;
  width: 100%;
}

.downline_users .Level {
  height: 800px;
  overflow-y: auto;
}

.container {
  max-width: 1440px !important;
  width: 100% !important;
}


@media all and (max-width: 1399px) {
  .container {
    max-width: 1440px !important;
    width: 100% !important;
    min-width: 100%;
  }
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm {
  margin: 0 0 30px !important;
  width: 100%;
  border: 0;
  background: transparent;
  box-shadow: none;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 15px;
  margin: 0 10px 0 0;
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm .row {
  margin: 0 10px 0 0;
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm input,
.col-md-9.dashboard-main .title+.signupform.main-sign-frm select {
  margin: 0 5px;
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm input[type="text"] {
  height: 37px;
  padding: 0 10px;
  border: 1px solid #ddd;
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm select {
  margin: 0 5px !important;
  height: 37px;
  padding: 0 10px;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: #fff url("https://dorecom.net/wp-content/uploads/2021/05/arrow.png") no-repeat center right 10px;
  background-size: 10px;
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm .get_commision {
  background: #06356a !important;
  color: #fff;
  padding: 8px 12px;
  height: 37px;
  line-height: 32px;
  font-size: 16px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border: 0;
  line-height: normal;
  border-radius: 5px;
}

.commission-table {
  width: 100%;
  border: 0;
  border: 1px solid #ddd;
}

.commission-table thead th {
  border: 1px solid #ddd;
  background: #f9f9f9;
  padding: 5px;
}

.commission-table tbody tr td {
  border: 1px solid #ddd;
  padding: 15px;
}

.commission-table tbody .date td {
  background: #06356a !important;
  color: #fff;
}

.commission-table tbody tr:nth-child(2n) td {
  background: #f9f9f9;
  color: #000;
}

.commission-table tbody tr td:last-child {
  /* display: flex; */
  /* justify-content: center; */
  height: auto;
}

.commission-table tbody tr td:last-child div {
  padding: 0 10px;
}

.first_name h3 {
  font-size: 16px !important;
}

.user_rank h3 {
  font-size: 16px;
}

.groups.tabs ul li {
  margin-right: 12px;
  margin-bottom: 21px;
}

li.active button {
  background: #fff !important;
  border: 1px solid #17386c !important;
  color: #16356a !important;
}

.box svg {
  width: 16px !important;
}

.lavel-box .first_name {
  padding-left: 22px !important;
  padding-top: 6px !important;
}



.commission-table td div {
  display: inline;
}

.pendingclawback {
  border: 1px solid #ddd;
}

.pendingclawback .col-md-4 {
  border-bottom: 1px solid #ddd;
}

.pendingclawback .row {
  margin-left: 0;
  margin-right: 0;
}

.pendingclawback .col-md-4 {
  border-bottom: 1px solid #ddd;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 12px;
  text-align: left;
}

ul.pagination {
  margin: 20px 0 0;
}

.container {
  max-width: 1672px !important;
  width: 100% !important;
}



.left-side {
  height: 100%;
}

div#treeWrapper {
  width: 1214px !important;
}

.rd3t-svg {
  overflow: visible;
  height: 100%;
  width: 100%;
  position: relative;
  margin: 8% auto 48% 66%;
}

.rd3t-svg rect {
  width: 171px;
  text-align: left;
  height: 170px;
}

text {
  transform: translate(-51px, 38px);
  font-size: 12px;
}

.usericon svg {
  width: 200px !important;
  height: 41px;
}

.user-Reser h5 {
  font-size: 16px;
  padding: 0;
  font-weight: 500;
  margin-bottom: 3px;
}

.icons svg {
  margin-right: 14px;
  width: 18px;
  height: 25px;
}

.deatis-info {
  /* margin-top: -21px; */
  /* border-top: 2px solid #000; */
  padding-top: 13px;
}

.current-part {
  margin-top: 10px;
  border-top: 2px solid #000;
  padding: 10px 0 0;
}

.current-part h3 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
}

.active-block h3 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
}

.active-block {
  border-top: 2px solid #000;
  margin: 10px 0 0;
  padding-top: 10px;
}

.active-block p {
  text-align: left;
  position: relative;
}

.active-block p:after {
  position: absolute;
  content: "\F00C";
  font-family: "FontAwesome";
  left: 0;
}

.active-block p {
  text-align: left;
  position: relative;
  line-height: 9px;
  padding-left: 27px;
  border-bottom: none;
  margin-top: 24px;
}

.active-block hr {
  display: none;
}

.deatis-info {
  margin-top: 20px;
}

.current-part {
  padding: 10px;
}

.Distributor.row {
  text-align: center;
  margin: 0 auto;
  width: 48%;
}

.Distributor.row h4 {
  font-size: 14px;
}

.dt-jned {
  font-size: 12px;
}

.Referal.code {
  font-size: 12px;
}

.Referal_url {
  font-size: 12px;
}

.Toal-Sale {
  font-size: 12px;
}

.Toal-commission {
  font-size: 12px;
  padding-right: 30px;
  position: relative;
}

.active-block {
  padding-left: 10px;
}

.active-block p {
  text-align: left;
  position: relative;
  line-height: 9px;
  padding-left: 27px;
  border-bottom: none;
  margin-top: 13px;
  font-size: 12px;
}

.leftsec {
  border: 2px solid #000;
  width: 280px;
  margin: 0 auto 0 0;
  padding-top: 14px;
  padding-bottom: 10px;
}

.DownlineStatic {
  margin: 20px 0 0;
  text-align: left;
  border: 2px solid #000;
  width: 278px;
  padding: 10px;
}

.DownlineStatic h3 {
  font-size: 15px;
}

.DownlineStatic {
  margin: 20px 0 0;
  text-align: left;
  border: 2px solid #000;
  width: 278px;
  padding: 10px;
}

.Distributor.row h4 {
  font-size: 14px;
  padding-top: 8px;
}

.col-md-8.statis_datas svg {
  width: 160px;
  height: 160px;
}

.Distributor.row svg {
  width: 134px;
}

.chart-LineGraph {
  width: 100%;
}

.chart-main {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  /* gap: 15px; */
}

.chart-main .total_commissonValue {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.chart-main h3 {
  display: none;
}

.total_commissonValue>div:first-child {
  font-weight: 700;
  color: #000;
}

/* .chart-main>div {
  max-width: calc(100%/2);
} */

.chart-PieCharts svg {
  max-width: 200px;
  height: 250px;
}

.Distributor.row svg {
  width: 134px;
  height: 39px;
}

.groups.tabs .tree li {
  margin-right: 0 !important;
}

.tree {
  width: 2300px;
}

.groups.tabs {
  width: 100%;
  float: left;
  /* overflow-x: scroll; */
}

/* end custom design tree */

.top-head-commission {

  display: flex;
}

.left-commison-sec {
  float: left;
  text-align: left;
}

.headsection-graphical {
  border: 2px solid #000;
  margin: 20px 0;
  padding: 10px;
}

.commsion.header {
  display: flex;
  border-bottom: 1px solid #000;
  justify-content: space-between;
  padding: 0px 0 10px;
  align-items: center;
}

.common-static-left {
  text-align: left;
}

.common-static-left h3 {
  font-size: 16px;
}

.common-static-right {
  font-size: 13px;
}

.headsection-graphical form.signupform.main-sign-frm {
  margin: 15px 0 0;
  width: 100%;
  border: none;
  box-shadow: none;
  padding: 0;
  display: flex;
  background: none;
}

.order_id_filter {
  display: flex;
}

.Status {
  display: flex;
}

.date_range_filter {
  font-size: 13px;
  vertical-align: top;
}

.order_id_filter {
  display: flex;
  font-size: 13px;
  padding-left: 15px;
  padding-right: 15px;
}

.order_id_filter input {
  height: 27px;
  margin-left: 10px;
}

.date_range_filter input[type="radio"] {
  vertical-align: middle;
  margin-left: 6px !important;
}

.Status {
  display: flex;
  font-size: 13px;
}

.Status select {
  margin-left: 10px;
  margin-right: 10px;
}

.get_commision {
  margin-left: 36px;
  background: none;
  margin-top: -2px;
}

.get_commision button {
  background: #00356a;
  border: none;
  border-radius: 3px;
  margin-top: 0;
  color: #fff;
  font-size: 13px;
  padding: 4px 20px;
  border-radius: 4px;
  vertical-align: top;
  transition: 0.3s ease all;
  border: 2px solid #00356a;
}

.get_commision button:hover {
  background: #fff;
}

.headsection-graphical {
  margin: 0;
  padding: 0px;
  border: none;
  margin-bottom: 25px;
}

.main-top {
  margin: 20px 0;
  padding-bottom: 60px;
}

.chart-PieCharts h2 {
  font-size: 17px;
}

.commsion-weekly-dashboard {
  border: 2px solid #000;
  padding: 10px;
}

.dashboard-commsion-header {
  display: flex;
  border-bottom: 1px solid;
  padding: 2px 0;
  justify-content: space-between;
}

.left-data-dashboard {
  float: left;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
}

.headingData {
  margin: 10px 0;
  float: left;
  font-weight: 600;
  color: #000;
}



canvas {
  width: 547px;
  float: left;
  text-align: left;
}

.rd3t-node {
  cursor: pointer;
  fill: #fff;
  stroke: #000;
  stroke-width: 2;
}

.left-commison-sec {
  display: flex;
}

.currentrank {
  display: flex;
  flex-wrap: wrap;
}

.current-rank-left {
  width: 153px;
}

.Toal-Sale,
.Toal-commission {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total-main-now {
  display: flex;
}

.total-main-now div:first-child {
  width: 238px;
}

.total-main-now {
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
  font-size: 13px;
}

.current-rank-right h3 {
  font-size: 13px;
}

h3.title1-heading {
  font-size: 11px;
}

.export-to-xls {
  text-align: right;
  max-width: calc(100% - 40px);
  /* width: 100%; */
  float: right;
}

.comistion-table-data {
  max-height: inherit;
  height: auto;
  /*overflow-y: auto;*/
  float: left;
  width: 100%;
}

table.commission-table th {
  position: sticky;
  top: 0;
  z-index: 2;
}


.headingData {
  margin: 24px 0 13px;
}

.order_id_filter {
  margin: 0;
}

span.status-head-1 {
  color: #000;
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm input[type="text"] {
  height: 27px;
  padding: 0 10px;
  border: 1px solid #ddd;
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm select {
  margin: 0 5px !important;
  height: 27px;
  padding: 0 10px;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: #fff url("https://dorecom.net/wp-content/uploads/2021/05/arrow.png") no-repeat center right 10px;
  background-size: 10px;
}

.mainorder-detail-sec .get_commision {
  text-align: right;
  margin-left: 0;
  width: 100% !important;
  max-width: 200px;
}

.Custom_filter {
  display: flex;
}

td.autoship-action button {
  margin: 0 8px;
  padding: 0 !important;
  vertical-align: top;
  font-size: 12px;
  line-height: 0;
  height: 23px;
  width: 57px;
}

td.action-btn button {
  font-size: 12px;
  height: 26px;
  margin: 0 10px;
}

table.commission-table td {
  text-align: center;
}

table.commission-table th {
  text-align: center;
}

.cart-product-details select {
  width: 100%;
  min-width: 124px;
}

.Add_to_cart.abc button {
  height: 45px;
  padding: 0;
  width: 130px;
}


button.disable {
  background: #aaa7a7 !important;
  border: 2px solid #aaa7a7 !important;
  color: #fff;
}

button.disable:hover {
  background: none !important;
  color: #aaa7a7 !important;
}

/* new */

.row.addres-page input {
  width: 100%;
  height: 41px;
  padding-left: 10px;
}

.select select {
  width: 100%;
  height: 40px;
  padding-left: 10px;
  border-color: #ddd;
  border-radius: 5px;
}

.row.addres-page input {
  width: 100%;
  height: 41px;
  padding-left: 10px;
  border-color: #ddd;
}



.Toal-Sale strong {
  padding-right: 10px;
  float: left;
  text-align: left;
}

.Toal-commission strong {
  padding-right: 10px;
  float: left;
}

.top-head-commission {
  border: 2px solid #000;
  padding: 10px 0;
  display: flex;
}

.left-commison-sec span {
  padding-right: 10px;
}

.left-commison-sec {
  float: left;
  text-align: left;
}

.left-commison-sec span {
  padding-right: 10px;
}

.order_table th {
  width: 16%;
}

button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.css-d0ukip-MuiButtonBase-root-MuiButton-root {
  background: #06356a;
  color: #fff;
  float: right;
}

.Cart_product.order-detail-sec th {
  padding: 12px;
}

button.re-order {
  margin-top: 16px;
}

.mainorder-detail-sec .container.order-detail-page button {
  margin-bottom: 0px;
  text-transform: capitalize;
  margin-bottom: 0;
  transition: 0.3s ease all;
  padding: 10px 25px;
  border-width: 2px !important;
  font-size: 14px;
  margin-top: 0px;
}

.mainorder-detail-sec .container.order-detail-page .main_order_detail_class button.add_new_card {
  margin: 0 0 20px 20px;
}

.comistion-table-data table td:last-child {
  display: table-cell !important;
}

.row.not-active {
  background: #33c2eb3b;
}

.dt-jned span {
  font-weight: 600;
}

.active-block p.check:after {
  position: absolute;
  content: "\F00C";
  font-family: FontAwesome;
  left: 0;
}

.active-block p.not-check:after {
  position: absolute;
  font-family: FontAwesome;
  left: 0;
  content: "\F00D";
}

.buttton-reem .btn {
  margin-bottom: 35px;
}

.left-profile-data label {
  width: 160px;
  margin-bottom: 20px;
}

.pro-fil-user .btn {
  width: 110px;
  height: 43px;
}

.orer-details-tables {
  border: 1px solid #ddd;
  margin: 0 0 25px;
  width: 100%;
}

.orer-details-tables table {
  width: 100%;
}

.orer-details-tables table th {
  border-bottom: 1px solid #ddd;
}

.orer-details-tables td {
  border-bottom: 1px solid #ddd;
  padding: 14px;
  border-right: 1px solid #ddd;
}

.orer-details-tables th {
  border-right: 1px solid #ddd;
}

.orer-details-tables th:last-child {
  border-right: 0;
}

.orer-details-tables td:last-child {
  border-right: 0;
}

.update-details .form-group {
  display: flex;
}

.update-details label {
  width: 209px;
}

.update-details span {
  position: absolute;

  left: 0;
  right: 0;
  margin: 35px auto;
  text-align: center;
}

.update-details input {
  margin-bottom: 14px;
}

.update-details span {
  position: absolute;
  left: 0;
  left: 0;
  right: 0;
  margin: 43px auto 0;
  text-align: center;
}

.right-side .form-group {
  display: flex;
}

.right-side label {
  width: 203px;
}

.wallet_data {
  float: right;
  width: 100%;
  text-align: right;
}

.buttton-reem {
  float: right;
}

.details_price {
  text-align: right;
}

/*Now the for li tree CSS*/

.tree ul {
  padding-top: 20px;
  position: relative;
  width: max-content;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.tree li::after,
.tree li::before {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

/*Remove space from the top of single children*/
.tree li:only-child {
  padding-top: 0;
}

/*Remove left connector from first child and 
right connector from last child*/
.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}

/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}

.tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}

.tree li .boxshape {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.tree li .boxshape:hover,
.tree li .boxshape:hover+ul li .boxshape {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #94a0b4;
}

/*Connector styles on hover*/
.tree li .boxshape:hover+ul li::after,
.tree li .boxshape:hover+ul li::before,
.tree li .boxshape:hover+ul ul::before,
.tree li .boxshape:hover+ul::before {
  border-color: #94a0b4;
}

ul.showing-node.hide {
  display: none;
}

/* end li box */
input[type="button"] {
  padding: 0 10px;
}

.mainorder-detail-sec.commision-now .Custom_filter {
  max-width: 86%;
  flex: 0 0 86%;
}

form.Create-order-form input {
  height: 34px;
  padding: 0 !important;
  margin: 0 !important;
  line-height: 20px;
  font-size: 11px !important;
  vertical-align: top;
}

span.error_create {
  color: red;
  position: absolute;
  bottom: -2px;
  font-size: 13px;
}

.form-group {
  position: relative;
}

.total_value {
  margin-bottom: 16px;
}

button.add-product-autoshiporder {
  margin: 15px 0;
  background: #06356a;
  border: none;
  color: #fff;
  padding: 7px 25px;
  border-radius: 5px;
}

button.add-product-autoshiporder {
  margin: 15px 0;
  background: #06356a;
  border: none;
  color: #fff;
  padding: 7px 60px;
  border-radius: 5px;
}

button {
  background: #06356a;
  border: none;
  color: #fff;
  padding: 7px 25px;
  border-radius: 5px;
}

.Create-order-form button {
  background: #06356a;
  border: none;
  color: #fff;
  padding: 7px 25px;
  border-radius: 5px;
}


.total_value strong {
  width: 108px;
  float: left;
}

span.sizer {
  display: none;
}

.search.selection.dropdown input[type="text"] {
  width: 50%;
  height: 45px;
  padding: 0 15px;
  border: 1px solid #cac2c2;
  margin: 15px 0 0;
  border-radius: 3px;
}

.Create-order-form button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator.css-osgkwv-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
  background: none !important;
  color: #000 !important;
  padding: 0 !important;
}

button.add-product-autoshiporder {
  padding: 7px 60px !important;
}

.total_value strong {
  width: 138px;
}

form.Create-order-form .form-group {
  margin-bottom: 30px;
}

span.error_create {
  color: red;
  position: absolute;
  bottom: 7px;
  font-size: 13px;
}

form.Create-order-form input.search {
  width: 41% !important;
  height: 48px !important;
  padding-left: 10px !important;
}



/* horizntal */
*,
*:after,
*:before {
  box-sizing: border-box;
}


#wrapper {
  position: relative;
}

.branch {
  position: relative;
  margin-left: 250px;
}

.branch:before {
  content: "";
  width: 50px;
  border-top: 2px solid #eee9dc;
  position: absolute;
  left: -100px;
  top: 50%;
  margin-top: 1px;
}

.entry {
  position: relative;
  min-height: 60px;
}

.entry:before {
  content: "";
  height: 100%;
  border-left: 2px solid #eee9dc;
  position: absolute;
  left: -50px;
}

.entry:after {
  content: "";
  width: 50px;
  border-top: 2px solid #eee9dc;
  position: absolute;
  left: -50px;
  top: 50%;
  margin-top: 1px;
}

.entry:first-child:before {
  width: 10px;
  height: 50%;
  top: 50%;
  margin-top: 2px;
  border-radius: 10px 0 0 0;
}

.entry:first-child:after {
  height: 10px;
  border-radius: 10px 0 0 0;
}

.entry:last-child:before {
  width: 10px;
  height: 50%;
  border-radius: 0 0 0 10px;
}

.entry:last-child:after {
  height: 10px;
  border-top: none;
  border-bottom: 2px solid #eee9dc;
  border-radius: 0 0 0 10px;
  margin-top: -9px;
}

.entry.sole:before {
  display: none;
}

.entry.sole:after {
  width: 50px;
  height: 0;
  margin-top: 1px;
  border-radius: 0;
}

.label {
  display: block;
  min-width: 150px;
  padding: 5px 10px;
  line-height: 20px;
  text-align: center;
  border: 2px solid #eee9dc;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -15px;
}

/* end */


input.product-qty {
  border: none;
}

span#quick-notifications-menu {
  width: 20px !important;
  height: 20px !important;
  border-radius: 20px;
  min-width: 20px !important;
}

.entry {
  min-height: 202px;
}

.branch {
  margin-left: 307px;
}

.wallet_data {
  float: none !important;
  text-align: left;
}

.Kaire_cash {
  display: flex;
}

.show-email-check,
.show-phone-check {
  width: 100%;
  position: absolute;
  display: flex;
  top: 39px;
  left: 152px;
  right: 0;
  text-align: center;
  margin: 0 auto;
}

.content {
  margin-left: 37px;
}



.update-details input[type="checkbox"] {
  height: 20px;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: none !important;
  box-shadow: none !important;
}

input.form-control:focus {
  box-shadow: none !important;
}

.wallet_data ul {
  display: flex;
}

.wallet_data ul li {
  display: inline-block;
}

.wallet_data ul {
  display: flex;
  margin-left: 0 !important;
  padding-left: 0;
}

.wallet_data button {
  margin: 0 10px;
  float: right;
}

.box-inner {
  display: flex;
}

.kaire_cash_ul li:nth-child(2) {
  width: 11%;
}


.box-inner {
  max-width: 60%;
  flex: 0 0 60%;
  display: flex;
}

.groupicon-dd {
  display: flex;
  flex-wrap: wrap;

}

.groupicon-dd .box-inner {
  max-width: 100%;
  flex: 0 0 100%;
}

.kaire_cash_ul div {
  padding-left: 10px;
}

.commission_cash_ul div {
  padding-left: 10px;
}

.clickable {
  cursor: pointer;
}

.mainorder-detail-sec.commision-now .Custom_filter {
  max-width: 83%;
  flex: 0 0 83%;
}

.mainorder-detail-sec.commision-now .Custom_filter,
.mainorder-detail-sec.refund-report.section .Custom_filter {
  flex: 0 0 76%;
  max-width: 76%;
}

.comission-data-table {
  overflow-y: auto;
  width: 100%;
}

span.name_d0 {
  font-size: 13px;
  line-height: 19px;
  display: block;
}

span.date_data {
  font-size: 13px;
}

.entry {
  min-height: 245px;
}

.branch:before {
  content: "";
  width: 72px;
  border-top: 2px solid #eee9dc;
  position: absolute;
  left: -122px;
  top: 50%;
  margin-top: 1px;
}

span.label.lable-translate {
  animation-name: fade-in-top;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}

.lavel-box .animations .box {
  animation-name: fade-in-top;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.branch.hide {
  /* -webkit-animation: fadeinout 1s linear forwards; */
  animation: fadeinout 1s;
}

@-webkit-keyframes fadeinout {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.branch.hide {
  opacity: 0;
}

.branch {
  opacity: 1;
  animation-name: fade-in-top;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}



.react-calendar__tile--active {
  background: #006edc !important;
  color: white;
}

.react-calendar button {
  background: #f8f9fa;
  border: none;
  color: #413636;
}

.react-calendar__tile {
  max-width: 100% !important;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}

.highlight {
  background: rgb(14 174 90 / 25%) !important;
}

.react-calendar__tile--active {
  background: #006edc !important;
  color: white !important;
}

.tooltip1 {
  position: relative;
}

.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 13px;
  position: fixed;
  z-index: 1;
}

.highlight:hover .tooltiptext {
  visibility: visible;
}

/* ens this section */

/* suetome date commision */
.custom-date-collection {
  position: absolute;
  top: 52px;
  left: 0;
}

.groups.tabs.commission-tab {
  position: relative;
  z-index: 0;
}

input#standard-required {
  border: none;
}

.fromdateshow {
  position: absolute;
  left: 0;
  z-index: 999999;
  top: 86px;
}

.tildate {
  position: absolute;
  left: 227px;
  z-index: 999999;
  top: 86px;
}

.react-calendar {
  position: relative;
  z-index: 999999;
}

.export-to-xls {
  margin-bottom: 23px;
  margin-right: 38px;
}


.transactions-data-table table {
  border: 1px solid #ddd;
}

.transactions-data-table table td {
  text-align: left;
}

.transactions-data-table table th {
  padding: 5px;
  background: #f9f9f9;
  text-align: left;
  padding-left: 14px;
  font-size: 15px;
  font-weight: 600;
}

.comission-data-table .commission-table {
  overflow-y: auto;
  width: 100%;
  position: unset;
  z-index: -1;
}

.get_commision button {
  padding: 1px 19px;
}

.clickableicon {
  cursor: pointer;
}

.details-list-section.hide,
.details-list-section1.hide {
  display: none;
}

.box-expand-btn {
  position: absolute;
  left: 187px;
  top: 31px;
}

.box .first_name {
  cursor: pointer;
}

.icon-name {
  cursor: pointer;
}

span.label.lable-translate .box-expand-btn {
  position: absolute;
  left: 155px;
  top: 33px;
}

/* end customdate */

ul.kaire_cash_ul {
  float: right;
}

.wallet_data {
  display: block !important;
  width: 100% !important;
  float: right !important;
  text-align: right !important;
}

ul.commission_cash_ul {
  float: right;
  width: 100%;
}

.wallet_data ul {
  display: block;
}

table.commission-table th {
  position: relative;
  z-index: 0;
}

.dashboard-table-commsion tr.MuiTableRow-root.main.css-1q1u3t4-MuiTableRow-root td {
  /* padding-left: 0; */
  text-align: left;
}

.dashboard-table-commsion tr.MuiTableRow-root.MuiTableRow-head.css-1q1u3t4-MuiTableRow-root th {
  text-align: left;
  /* padding-left: 0; */
}



.comistion-table-data.dashboard-table-commsion td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-1ex1afd-MuiTableCell-root {
  padding: 0 !important;
}

/* table.MuiTable-root.inner-table.css-rqglhn-MuiTable-root td:first-child {
  width: 225px;
} */

table.MuiTable-root.inner-table.css-rqglhn-MuiTable-root td {
  text-align: left;
}

.image-item {
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

/* design downline finel */
.groups.tabs .defaultshow-down {
  display: flex;
  width: 100%;
}

.groups.tabs .icon-count {
  display: flex;
}

.groups.tabs .label {
  min-width: 235px;
  padding: 5px;
}

.groups.tabs .icon img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  margin-right: 10px;
}

.branch:before {
  left: -75px;
}

span.label.lable-translate .icon {
  margin-right: 10px;
}

.groups.tabs .icon img {
  width: 25px;
  height: 25px;
  object-fit: cover;
  margin-right: 10px;
}

span.label.lable-translate .icon {
  margin-right: 10px;
}

span.name_d0 {
  line-height: 15px;
}

.icon-name {
  font-weight: 600;
  padding: 3px 0 0;
}



.groups.tabs .label {
  padding: 0;
}

.icon-count span {
  background: #ddd;
  position: absolute;
  right: 0;
  height: 25px;
  width: 36px;
  padding-top: 3px;
}



.groups.tabs .icon img {
  width: 25px;
  height: 25px;
  object-fit: cover;
  margin-right: 10px;
  padding: 2px;
}

span.name_d0 {
  line-height: 19px;
  font-size: 12px;
}

span.label.lable-translate .icon {
  margin-right: 10px;

  height: 25px;
  padding: 2px;
}



span.name_d0 svg {
  margin-top: 2px !important;
}

.icon-name {
  max-width: 100%;
  flex: 0 0 100%;
  float: left;
  text-align: left;
}

span.label.lable-translate .icon {
  margin-right: 0;
  height: 25px;
  padding: 2px;
}

.box svg {
  width: 10px !important;
  margin: 0;
}



.entry:before {
  height: 100%;
  border-left: 1px dashed #eee9dc;
}

.entry:after {
  width: 50px;
  border-top: 1px dashed #eee9dc;
  top: 50%;
}

.entry:last-child:after {
  height: 10px;
  border-top: none;
  border-bottom: 1px dashed #eee9dc;
  border-radius: 0 0 0 10px;
  margin-top: -9px;
}

.branch:before {
  border-top: 1px dashed #eee9dc;
}


.icon-name {
  width: 158px;
  font-weight: 600;
  padding: 3px 0 0;
  max-width: inherit;
  flex: initial;
}

.icon-count .name_d0 {
  background: #ddd;
  width: 38px;
  justify-content: center;
}

.groups.tabs .label {
  min-width: 220px;
  padding: 5px;
}

.icon-count svg {
  margin-right: 9px;
  margin-top: 4px;
}

span.name_d0 svg {
  margin-top: 4px !important;
}

span.counts_user-d {
  position: absolute;
  right: 5px;
  top: 1px;
}

.groups.tabs .icon img {
  width: 21px;
  height: 21px;
  object-fit: cover;
  margin-right: 10px;
  padding: 2px;
}

.icon svg {
  width: 13px !important;
  height: 19px !important;
}

.groups.tabs .label {
  min-width: 220px;
  padding: 0;
}

.icon-name {
  width: 100%;
}

span.counts_user-d {
  position: absolute;
  right: 5px;
  top: 1px;
  background: none;
  width: auto;
  padding-top: 0;
}

span.name_d0 svg {
  margin-top: 4px !important;
  width: 29px !important;
}

span.counts_user-d {
  position: absolute;
  right: 5px;
  top: 4px;
  background: none;
  width: auto;
  padding-top: 0;
}

span.counts_user-d {
  position: absolute;
  right: 5px;
  top: 4px;
  background: none;
  width: auto;
  padding-top: 0;
  font-size: 12px;
}

span.name_d0 svg {
  margin-top: 3px !important;
  width: 29px !important;
}

span.name_d0 svg {
  margin-top: 0 !important;
  width: 29px !important;
}

.groups.tabs .label {
  min-width: 220px;
  padding: 0;
  border: none;
}

.box {
  border: 1px solid #ddd;
  border-radius: 3px;
}


.details-list-section {
  border: 1px solid #ddd;
  margin: 10px 0;
  border-radius: 0px;
  box-shadow: 0 0 10px #ddd;
}

.hovershow {
  display: flex;
  padding: 0 10px;
}

.hovershow .icons svg {
  width: 12px;
}

.details-list-section .data {
  padding: 0 10px;
}

span.date_title {
  font-size: 13px;
  padding-right: 3px;
  padding-left: 0;
}

span.date_title svg {
  margin-right: 6px;
}

span.total_order_title {
  font-size: 13px;
}

span.sale_title {
  font-size: 13px;
}

span.total_order_title svg {
  margin-right: 8px;
}

span.sale_title svg {
  margin-right: 8px;
}

.details-list-section {
  border: 1px solid #ddd;
  margin: 10px 0;
  border-radius: 3px;
  box-shadow: 0 0 10px #ddd;
  height: 93px;
}

span.date_data strong {
  font-size: 11px;
}

span.total_order_data strong {
  font-size: 11px;
}

span.sale_data strong {
  font-size: 11px;
}

.entry.firstlevel:before {
  display: none;
}

.branch:before {
  left: -84px;
}

.entry.firstlevel:after {
  display: none;
}

.icon svg {
  width: 27px !important;
  height: 19px !important;
}

.icons a svg {
  width: 9px;
  margin-right: 4px;
}

.user_rank h3 {
  font-size: 13px;
  width: 174px;
  float: left;
  text-align: left;
}

.branch.lv1.level0::before {
  display: none;
}

.entry {
  min-height: 42px;
}

/* end */

/* horizontal view */
.tree li .boxshape {
  border: none;
}

.box {
  border: none;
}

.tree li .boxshape:hover,
.tree li .boxshape:hover+ul li .boxshape {
  background: transparent;
  color: #000;
  border: 1px solid transparent;
}


.tree {
  width: 2486px;
}

.show_on_hover {
  box-shadow: 0 0 10px #ddd;
  width: 251px;
  height: 193px;
  padding: 15px;
  border-radius: 4px;
}

.tree_top_details h3 {
  font-size: 15px !important;
  font-weight: 600;
  color: #000;
}

.data {
  display: flex;
  float: left;
  width: 100%;
  margin-bottom: 0;
  margin-top: 10px;
  align-items: center;
}

.box .data svg {
  width: 15px !important;
  margin: 2px 3px 0;
  color: #000;
}

.tree_top_details .box svg {
  width: 15px !important;
  margin: -5px 3px 0;
  color: #000;
}

.data strong,
.data {
  font-size: 13px;
}

.show_on_hover {
  box-shadow: 0 0 10px #ddd;
  width: 250px;
  height: 150px;
}

span.count-val {
  background: #000;
  width: 28px;
  height: 28px;
  padding: 6px;
  color: #fff;
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tree li .boxshape:hover,
.tree li .boxshape:hover+ul li .boxshape {
  background: transparent;
  color: #000;
  border: none;
}

.show_on_hover::after {
  background-image: url("/downline.jpg") !important;
  position: absolute;
  background-size: 24px;
  content: "";
  bottom: -93px;
  width: 103px;
  height: 95px;
  margin: 0 auto;
  left: 65px;
  right: 0;
  background-repeat: no-repeat;
}

.boxshape .box svg {
  width: 16px !important;
  margin: 3px 3px 0 !important;
}

.tree .tree_top_details svg {
  margin-top: -4px !important;
  color: #000;
  width: 13px !important;
}

.first_name h3 {
  text-transform: uppercase;
  font-size: 15px !important;
  padding: 10px 0 0 !important;
}

.boxshape:hover .default_info+.show_on_hover {
  visibility: visible;
}

.show_on_hover {
  display: block !important;
  position: absolute;
  top: 0;
  transform: translate(-22%, -98%);
  z-index: 9;
  visibility: hidden;
  position: relative;
  display: none;
}

.profile-img-d {
  height: 100px;
  overflow: hidden;
}

.profile-img-d img {
  object-position: center;
}

/* end */

/* new design implementataion */

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0 !important;
  border: none;
  font-family: var(--common-font-bd);
}

.header-section .nav li a {
  color: #fff;
  text-decoration: none;
}

.header-section .navbar {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0px 15px;
  margin-bottom: 0;
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 0 15px;
}

.header-section ul.nav.navbar-nav.navbar-left {
  padding-left: 20px;
}

.header-section .nav>li>a {
  padding-right: 10px;
  padding-left: 10px;
}

body .container {
  width: 1672px;
}

.image-welcome img {
  width: 100%;
}

.welcome-section {
  padding: 46px 0;
}

.welcome-text h4 {
  font-size: 45px;
  color: #000;
  font-family: var(--common-font-bd);
  margin-bottom: 0;
}

.welcome-text p {
  font-size: 17px;
  color: #000;
  font-weight: 500;
  line-height: 28px;
  padding-top: 10px;
}

.text-welcome h2 {
  margin: -82px 0 0;
  text-align: right;
  font-size: 60px;
  color: #fff;
  font-weight: normal;
  font-family: var(--common-font-bd);
}

.par-text {
  width: 1234px;
  margin: 103px auto;
}

.par-text p {
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  font-family: var(--common-font-bd);
}

.welcome-text.maritime-text h4 {
  text-align: center;
}

.welcome-text.maritime-text {
  padding: 30px 42px;
  width: 515px;
  transform: translate(-113px, 96px);
}

.product-main {
  text-align: center;
}

.product-main img {
  width: 100%;
  margin: 10px 0;
}

a.btn.btn-learn {
  background: #00356a;
  border-radius: 25px;
  color: #fff;
  padding: 5px 20px;
  display: inline-flex;
  justify-content: center;
  height: 40px;
  max-width: 150px;
  font-size: 15px;
}

.lean-main {
  text-align: center;
  /* margin-top: -16px; */
  margin-bottom: 20px;
}

.fotter_section {
  /* background: #00356a; */
  text-align: center;
  float: left;
  width: 100%;
  padding: 30px 0 80px;
  background-size: contain;

}


.fotter-list ul li a {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
}

.fotter-list ul li a:hover {
  color: #eed7d7;
}

.fotter-list ul {
  display: flex;
  justify-content: center;
}

.fotter-listdetail ul {
  display: flex;
  justify-content: center;
}

.fotter-listdetail ul li a {
  color: #fff;
}

.par-text p {
  /* padding-bottom: 24px; */
  margin-bottom: 0;
  font-family: var(--common-font-Lt);
}

.welcome-text.maritime-text p {
  text-align: center;
  font-size: 15px;
  padding-top: 10px;
  width: 516px;
  margin: 0 auto;
}

.fotter-list {
  padding: 20px 0;
}

.fotter-list ul li a {
  border-right: 1px solid #fff;
  padding: 0 15px;
}

.fotter-list li:last-child a {
  border-right: none;
}

.fotter-listdetail li a {
  font-size: 14px;
}

.fotter-listdetail li {
  padding: 0 50px;
}

span.loc1 {
  display: block;
  padding: 10px 0;
}

.question-text {
  margin-top: -60px;
  margin-bottom: 60px;
}

.question-text h1 {
  font-size: 99px;
  color: #fff;
  margin: 0 0 150px;
  padding: 0 95px;
  font-weight: bold;
}

.stay-touch-part {
  max-width: 918px;
  margin: 0 auto;
}

.form-subscribe {
  max-width: 945px;
  margin: 0 auto;
}

.form-subscribe .form-group {
  width: 100%;
}

form.form-inline {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

input#staticEmail {
  border: none;
  background: none;
  margin: 0 0 0 0px;
  border-bottom: 1px solid #000;
  width: 100%;
  font-size: 17px;
}

.form-subscribe .btn {
  background: #000;
  border: none;
  border-radius: 25px;
  width: 123px;
  height: 38px;
  font-size: 17px;
  margin-top: 10px;
}

/* Previous button  */
.media-carousel .carousel-control.left {
  left: -12px;
  background-image: none;
  background: none repeat scroll 0 0 #00356a;
  border: none;
  border-radius: 0;
  height: 40px;
  width: 40px;
  margin-top: 30px;
  top: 33%;
  opacity: 1;
  padding-top: 0;
  font-size: 28px !important;
}

/* Next button  */
.media-carousel .carousel-control.right {
  right: -12px !important;
  background-image: none;
  background: none repeat scroll 0 0 #00356a;
  border: none;
  border-radius: 0;
  height: 40px;
  width: 40px;
  margin-top: 30px;
  top: 33%;
  opacity: 1;
  padding-top: 0;
  font-size: 28px !important;
}

/* Changes the position of the indicators */
.media-carousel .carousel-indicators {
  right: 50%;
  top: auto;
  bottom: 0;
  margin-right: -19px;
}

/* Changes the colour of the indicators */
.media-carousel .carousel-indicators li {
  background: #c0c0c0;
}

.media-carousel .carousel-indicators .active {
  background: #333333;
}

/* End carousel */
.product-main .thumbnail {
  background: transparent;
  border: none;
  margin-bottom: 0;
  text-decoration: none;
}

.product-main img {
  max-width: 180px;
  margin: 0 auto;
  height: 230px;
  object-fit: fill;
}

.media-carousel {
  margin-bottom: 0;
  padding: 0 80px 30px;
  margin-top: 30px;
}

.product-main {
  background: #fff;
  text-align: center;
  justify-content: center;
  margin: 0 20px;
  padding: 30px 0 10px;
}

.product-main h5 {
  font-family: var(--common-font-bd);
  color: #000;
}

.home-page>.slider-main {
  margin: 150px 0 100px;
}

.welcome-section.pycn-section {
  position: relative;
  padding: 0;
  /* z-index: 999999999999; */
}

.product-main1 {
  text-align: center;
  margin: 30px 0 11px;
}

.btn-shop-all {
  text-align: center;
}

.btn-shop-all a {
  text-align: center;
  background: #00356a;
  border: 2px solid #00356a;
  border-radius: 25px;
  width: 100%;
  height: 50px;
  font-size: 17px;
  color: #fff;
  font-family: var(--common-font);
  align-items: center;
  line-height: normal;
  transition: 0.3s ease all;
  display: inline-flex;
}

.btn {
  max-width: 240px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  font-family: var(--common-font);
  align-items: center;
  transition: 0.3s ease all;
  line-height: normal;
}

.dashboard_div a {
  display: flex;
}



li.logout_icon svg {
  color: #fff !important;
  margin: 0 0 0;
}

li.notifications svg {
  color: #fff !important;
  margin-top: 25px;
}

.icon-box-cart {
  position: relative;
}

.icon-box-cart span {
  background: var(--blue);
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  display: flex;
  position: absolute;
  top: -12px;
  left: 0;
  padding-left: 0;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: 'Graphik-bold';
}



.header-section .navbar-right li {
  padding: 0 5px;
}

ul.dropdown-menu-for-cart li {
  cursor: pointer;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  /* margin-bottom: 10px; */
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  text-align: left;
}

ul.dropdown-menu-for-cart {
  background: #fff;
  width: 250px;
  height: 250px;
  overflow-y: scroll;
  position: absolute;
  right: -31px;
  z-index: 99;
  padding: 0;
  border-top: 4px solid #06356a;
}

li.notifications:hover .dropdown-menu-for-cart {
  display: block;
  z-index: 99999;
}

ul.dropdown-menu-for-cart {
  display: none;
}

ul.dropdown-popup_viewCart img {
  width: 30px;
}

ul.dropdown-popup_viewCart li {
  display: flex;
}

ul.dropdown-popup_viewCart .txt {
  color: #000;
}

ul.dropdown-popup_viewCart {
  background: #fff;
}

ul.dropdown-popup_viewCart li a {
  background: #06356a;
  width: 100%;
  text-align: center;
  padding: 0;
  line-height: 1px;
  margin-bottom: 0;
  height: 47px;
  margin-top: 16px;
}

ul.dropdown-popup_viewCart .txt {
  color: #000;
  padding-left: 15px;
}

ul.dropdown-popup_viewCart {
  background: #fff;
  width: 300px;
  padding: 10px;
}

ul.dropdown-popup_viewCart {
  display: none;
}

ul.dropdown-menu-wishlist {
  display: none;
}

li.notifications span {
  background: red;
  border-radius: 50%;
  width: 19px !important;
  height: 19px !important;
  display: block;
  position: absolute;
  padding-left: 4px;
  border: 2px solid #fff;
  line-height: 14px;
  font-size: 11px;
  top: 20px;
  left: 10px;
}

span#quick-notifications-menu {
  width: 9px !important;
  height: 23px !important;
  min-width: 23px !important;
  line-height: 9px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}



.cart span,
.icon-box span {
  background: #fff;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  display: block;
  color: #000;
  position: absolute;
  top: -12px;
  left: 3px;
  padding-left: 7px;
  line-height: 21px;
  font-size: 10px;
  font-family: 'Graphik-bold';
}

li.view_cart_section:hover .dropdown-popup_viewCart {
  display: block;
}

li.wishlist_section:hover .dropdown-menu-wishlist {
  display: block;
  position: absolute;
  left: -144px;
  z-index: 99;
}

ul.dropdown-popup_viewCart img {
  width: 41px;
}

ul.dropdown-menu-for-cart li {
  padding: 10px;
}

ul.dropdown-menu-for-cart li:hover {
  background: #06356a;
  color: #fff;
}

.login_signin {
  display: flex;
  align-items: center;
}

.upper_lower_div ul.nav.navbar-nav.navbar-right .login_signin a {
  padding: 0;
}

.upper_lower_div ul.nav.navbar-nav.navbar-right .login_signin a:first-child {
  padding: 0 10px 0 0;
}

/* 1920 pixal design css for home page */
.home-page .container {
  width: 1440px !important;
}



.welcome-text {
  background: #fff;
  padding: 39px 49px;
  width: 422px;
  min-height: 370px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  text-align: center;
}


.welcome-text.maritime-text {
  padding: 13px 26px;
  width: 620px;
  transform: translate(-113px, 57px);
}

.par-text {
  width: 1234px;
  margin: 91px auto 24px;
}

.welcome-text.maritime-text h4 {
  font-size: 40px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0;
  width: 319px;
  margin: 7px auto;
}

.product-main1 img {
  width: 300px;
}

.lean-main {
  text-align: center;
  /* margin-top: -27px; */
}

.welcome-text.maritime-text {
  padding: 13px 26px;
  width: 570px;
  transform: translate(-113px, 81px);
}

div#media {
  width: 1470px !important;
  margin: 0 auto;
  padding: 0 15px;
}

.btn-shop-all {
  margin-top: 37px;
}

.question-text h1 {
  font-size: 80px;
}

.form-subscribe label {
  font-size: 48px;
  font-family: var(--common-font-bd);
  font-weight: normal;
}

.form-subscribe {
  max-width: 100%;
  margin: 0;
  text-align: center;
}

.stay-touch-part h5 {
  font-weight: 300;
  position: absolute;
  top: 145px;
  margin-left: 0px;
  font-size: 24px;
}

.form-subscribe .btn {
  margin-left: 0px;
}

.question-text {
  margin-bottom: 100px;
}


.silck-slide-home .slick-slide {
  padding: 0 20px;
}

.product-main {
  margin: 0 20px;
}

div#media {
  width: 1380px !important;
  margin: 0;
  padding: 0;
}

.silck-slide-home .button_pre {
  left: 0;
}

.button_next {
  right: 0;
}

.silck-slide-home .button_next,
.silck-slide-home .button_pre {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #06356a;
  z-index: 999;
  border-radius: 5px;
  color: #fff;
  font-size: 11px;
}

.silck-slide-home .slick-slider.slick-initialized {
  width: 1212px;
  margin: 0 auto 0 68px;
  justify-content: center;
}

.product-main h5 {
  font-size: 20px;
  margin: 9px 0 0px;
  text-transform: uppercase;
}

.product-main>svg {
  font-size: 40px;
  color: #bbbbbb;
}

.product-main:hover svg {
  color: var(--blue);
}

.carousel img {
  width: 200px !important;
  display: inline-block;
  pointer-events: none;
  height: 200px !important;
  object-fit: cover;
}

.mainorder-detail-sec.commision-now .Custom_filter {
  max-width: 86%;
  flex: 0 0 86%;
}

.fotter_section .fotter-list ul li {
  list-style: none;
}

.fotter_section .fotter-listdetail {
  list-style: none;
}

.fotter_section .row {
  margin: 0 !important;
}

.lavel-box .row {
  display: flex;
  width: 100%;
  clear: both;
}

.col-md-9.dashboard-main .title+.signupform.main-sign-frm .get_commision {
  padding: 3px 0 !important;
  height: 37px;
  line-height: 32px;
  white-space: nowrap;
}

button.btn:focus {
  outline: none;
}

.add_address_class_from_autoship,
.add_address_from_CheckoutPage,
.change_passsword_from_profile,
.wishlist_popup_page,
.view_cart_popup {
  min-width: 695px !important;
}

.product_add_from_autoship {
  margin: 0 auto;
  max-width: 750px !important;
}

.comistion-table-data.dashboard-table-commsion td {
  font-size: 12px;
}

.error {
  font-size: 12px;
  color: #f00;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.tooltip_main_class {
  font-size: 12px !important;
}

button.btn.btn-larn {
  background: #00356a;
  border: 2px solid #00356a;
  border-radius: 25px;
  width: 100%;
  height: 40px;
  max-width: 140px;
  font-size: 17px;
  color: #fff;
  margin-top: 18px;
  transition: 0.3s ease background;
}

button.btn.btn-larn:hover {
  background: #fff;
  color: #00356a;
}

.container_of_slider {
  display: none;
}

.pie_cahrt_pop {
  width: 220px;
}

.tooltip_section {
  display: flex;
  gap: 4px;
}

.pie_cahrt_pop p {
  font-size: 14px;
  margin-bottom: 0;
  text-align: center;
}

.tooltip_section {
  justify-content: center;
}

.pie_cahrt_pop .mouseover_class {
  font-size: 12px;
}

.Custom_filter button.react-calendar__tile.react-calendar__month-view__days__day {
  border: 1px solid #e6e6e6 !important;
  background: #e6e6e6 !important;
  color: #000 !important;
}

.react-calendar__navigation button {
  background: #e6e6e6 !important;
  border: transparent !important;
}

form.signupform.main-sign-frm input#standard-required {
  padding: 10.5px 14px;
  font-size: 14px;
}


.css-1k51tf5-MuiTooltip-tooltip {
  font-size: 14px !important;
  max-width: 500px !important;
}

/* pooja 27Oct */
.stay-touch-part h5 {
  top: 0;
  margin-left: 87px;
  position: unset;
  margin-bottom: 0;
}

li.wishlist_section:hover .dropdown-menu-wishlist {
  position: absolute;
  left: auto;
  right: 0;
  z-index: 999999;
  width: 100%;
  min-width: 285px;
}

li.wishlist_section.from_navBar_section {
  position: relative;
}

.Toastify__toast-container--top-right {
  top: 5em;
}

.header-section {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  background-size: cover;
}

.container.order-detail-page>h2 {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .order_table.address-list.order-list.auto-ship-order-table.smartship {
    overflow-x: auto;
  }
}

@media (min-width: 767px) {
  .silck-slide-home button[type="button"] {
    display: none;
  }
}


@media (max-width: 991px) {
  .header-section {
    background-size: 100%;
  }

  .container.order-detail-page.order-history .order_table.address-list.order-list table.commission-table td.action-btn {
    padding-top: 15px !important;
  }

}

/* 28-oct-2021 */
.product-slider .thumb-slider .slick-vertical .slick-slide {
  height: 141px !important;
  margin-top: 0 !important;
}

.product-slider-now .slick-slider img {
  height: 550px !important;
}

.product-slider .thumb-slider .slick-list {
  padding-top: 0 !important;
}

.study-now {
  transform: translateY(35%) !important;
}

/* 23-nov-2021 */
.Toastify__toast-container--top-right {
  top: 6em;
}

/* animation svg background */
.animated-gradients.position-fixed {
  height: 100%;
  overflow: hidden;
  width: 100%;
  z-index: -1;
  top: 0;
  position: fixed !important;
}

.animated-gradients.position-fixed svg {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}

.welcome-section.gauratee-section .welcome-text {
  text-align: center;
  padding: 5px 10px 20px;
  width: 380px;
  transform: translate(32px, 55px);
}

.welcome-section.gauratee-section p {
  padding-top: 10px;
  width: 100%;
  max-width: 348px;
  margin: 0 auto;
}

.welcome-section.gauratee-section {
  padding: 92px 0 5px;
}

.welcome-section.gauratee-section .welcome-text p {
  font-size: 15px;
  color: #000;
}

.autoship_price_for_true,
.allproduct_autoship {
  color: #000;
  font-size: 14px;
  padding-right: 0;
  align-items: center;
  display: flex;
  width: 100%;
  max-width: fit-content;
}

.autoship_price_for_true svg {
  width: 23px;
  height: 23px;
  border-radius: 30px;
  border: 1px solid var(--blue);
  padding: 3px;
  margin-left: 5px;
  background: #c9d9ff;
  color: var(--blue);
}

/* 27-12-2021 */


/* .navbar-toggle {
  display: none !important;
} */


/* 19-01-2022 */
.mini-cart-sec .checkout-btn {
  background: #fff;
}

.mini-cart-sec .checkout-btn ul {
  margin-top: 10px;
}

.form-subscribe .btn {
  width: 100%;
  max-width: 125px;
  flex: 125px;
  margin-right: 0;
  border: 2px solid #000;
}

.form-subscribe .btn:hover {
  background-color: #fff;
  color: #000;
}

.question-text .stay-touch-part {
  margin-left: 65px;
}

.form-subscribe .form-inline .form-group {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  width: 100%;
  max-width: calc(100% - 250px);
  flex: calc(100% - 250px);
}

.form-subscribe label {
  margin-bottom: 0;
  line-height: normal;
}

.form-subscribe .form-inline .form-group label.col-form-label {
  max-width: 260px;
  flex: 260px;
}

.form-subscribe .form-inline .form-group input#staticEmail {
  max-width: calc(100% - 350px);
  flex: calc(100% - 350px);
}

.dt-jned {
  text-align: right;
}

.current-rank-left,
.current-rank-right {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main_dashboard_section_class .dt-jned span,
.orer-details-tables table tr th:first-child {
  text-align: left;
}

.row.order-cstm table.table.inner-table-shop td {
  text-align: revert;
}

.MuiCollapse-vertical .MuiBox-root td.MuiTableCell-root {
  border-bottom: 0;
}

.total_sale .Toal-commission+.Toal-commission {
  margin-top: 8px;
  position: relative;
}

.Toal-commission span {
  align-items: center;
  display: flex;
  position: absolute;
  right: 10px;
}

.dashboard_div,
.icon-box-cart {
  position: relative;
}

.upper_lower_div {
  justify-content: flex-end;
}

.upper_lower_div ul.nav.navbar-nav.navbar-right {
  margin-right: 0;
}

ul.nav.navbar-nav.navbar-top li {
  float: none;
  justify-content: flex-end;
}

.upper_lower_div ul.navbar-top li:first-child a:last-child {
  padding-bottom: 0;
  padding-right: 8px;
}

.upper_lower_div ul.navbar-top li:first-child a {
  text-decoration: none;
}

.upper_lower_div ul.navbar-top li span {
  color: #fff;
  padding-top: 0;
  line-height: normal;
  margin-top: 0px;
}

.view_cart_popup {
  max-width: 1020px !important;
  max-height: calc(100% - 64px);
  width: 100%;
}

/* 24-03-2022 */
.welcome-text h4 strong {
  font-family: var(--common-font);
  font-weight: 300;
}

.silck-slide-home .button_next,
.silck-slide-home .button_pre,
a.btn.btn-learn {
  transition: 0.3s ease all;
  border: 2px solid #06356a;
}

.silck-slide-home .button_next:hover,
.silck-slide-home .button_pre:hover,
.btn-shop-all a:hover,
a.btn.btn-learn:hover {
  background: #fff;
  color: #06356a;
}

.reply_chat_section_main_dialog {
  background: #021a35;
}

.reply_chat_section_main_dialog>div {
  padding: 0;
}

.customPackPopup {
  max-width: 1080px !important;
}

.customPackPopup .container_for_load_data {
  max-height: 600px;
}

.radio-bnt {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.radio-bnt:after {
  content: "";
  width: 40px;
  height: 40px;
  background: #e9f3fd;
  position: absolute;
  right: -6px;
  top: -6px;
  border-radius: 50%;
  transition: 1s ease-in-out all;
  z-index: -1;
}

.radio-bnt:hover:after {
  transform: scale(40);
}


.container.checkout-main button {
  border-radius: 40px;
  min-width: 45px;
  height: 45px;
  border: 2px solid #00356a !important;
}

.container.checkout-main button[disabled] {
  border: 2px solid #999 !important;
}

.container.checkout-main button:hover {
  background: #fff;
  color: #00356a;
}

.apply-coupn form.signupform input {
  border: none;
  box-shadow: none;
  height: 100%;
  min-height: 45px;
}

.apply-coupn form.signupform {
  border-radius: 40px;
  border: 1px solid #ddd;
  overflow: hidden;
}

.apply-coupn,
.kairecassh {
  background: #fff;
}

table.Cart_product {
  background: #fff;
  border-radius: 0px;
  overflow: hidden;
}

/* 15-04-2022 */
.main_dashboard_section_class .dt-jned span:last-child {
  max-width: 20px;
  flex: 1;
  text-align: right;
}

.main_dashboard_section_class .dt-jned span:nth-child(2) {
  text-align: right;
  flex: 1;
  max-width: inherit;
  word-break: break-all;
  /* padding-right: 10px; */
}

.main_dashboard_section_class .dt-jned {
  align-items: center;
}

.deatis-info .dt-jned span:first-child {
  max-width: 190px;
  text-align: left;
}

.current-part+br {
  display: none;
}

/* 18-05-2022 */
.groups.tabs ul.tabs {
  /* max-width: calc(100% - 40px); */
  margin: 0 auto;
  float: none;
  display: flex;
  padding: 0;
}

.Custom_filter .MuiFormControl-root input,
.Custom_filter .MuiFormControl-root * {
  font-size: 15px;
  color: #000;
  text-transform: capitalize;
  font-weight: 500;
  margin: 0;
}

.Custom_filter .MuiFormControl-root input {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

.Custom_filter .MuiInput-root:before {
  display: none;
}

button.MuiButtonBase-root {
  font-size: 16px;
}

.css-karnbp .MuiTypography-root.MuiTypography-caption {
  font-size: 16px;
  font-weight: 600;
  color: #00356b;
}

.css-8v7him .css-9a6yxo {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.css-1lomthf button span {
  font-size: 14px;
  text-transform: capitalize;
  color: #000;
  margin: 0;
}

.PrivatePickersToolbar-root>span.MuiTypography-root {
  color: #000;
  font-size: 11px;
}

.Custom_filter .MuiFormControl-root label {
  padding: 0 5px;
  bottom: 30px;
  top: auto;
  left: 10px;
  z-index: 1;
  background: #fff;
}

.MuiDialogActions-root button.MuiButton-text.MuiButton-textPrimary {
  background: #00356b;
  border-radius: 30px;
  font-size: 14px;
  padding: 7px 20px;
  color: #fff;
  font-family: 'Lato';
  min-width: 90px;
  border: 2px solid #00356b;
  font-weight: 600;
  margin: 0;
}

.MuiDialogActions-root button.MuiButton-text.MuiButton-textPrimary:hover {
  background: #fff;
  color: #00356b;
}

.Custom_filter .MuiFormControl-root .MuiInput-root:after {
  display: none;
}

.MuiPaper-root.MuiPaper-elevation .MuiDialogActions-root {
  gap: 10px;
}

table.table.inner-table-shop {
  margin-bottom: 0;
}

.order-detail-page.main_address_page_listing .button-top {
  margin: 0;
}

.mainorder-detail-sec .container.order-detail-page .autoship-action button {
  border-radius: 0;
  background: none;
  border: none;
  width: auto;
}

.mainorder-detail-sec .container.order-detail-page .autoship-action button svg {
  width: 27px;
  height: 27px;
  margin: -13px 0 0;
  fill: #eba72a;
}

.edit_address button {
  width: auto;
}

table.Cart_product.whistlist-table tr:last-child td {
  padding-top: 15px;
}

.top-head-commission>div {
  letter-spacing: -0.2px;
}

.row.cupon_and_kiare_section .coupam-enter {
  padding: 0;
}

.row.cupon_and_kiare_section .apply-coupn {
  margin: 0;
  padding: 20px;
  height: 100%;
}

.row.cupon_and_kiare_section .apply-coupn .col-md-12 {
  padding: 0;
}

/* 01-06-2022 */
.Loader::before {
  content: '';
  float: left;
  padding-top: 100%;
}



.Loader__Circle {
  display: block;
  position: absolute;
  border-radius: 50%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.8;
  mix-blend-mode: screen;
  filter: brightness(120%);
  -webkit-animation-name: SpinAround;
  animation-name: SpinAround;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear
}


div#careers>.medium-margin {
  margin-bottom: 0;
}

.Loader.loader-main {
  width: 170px;
  min-width: 110px;
  overflow: visible;
  margin: 20px;
  border-radius: 50%;
  box-shadow: inset 0 0 8px rgb(255 255 255 / 40%), 0 0 25px rgb(255 255 255 / 80%);
  z-index: 99;
  position: relative;
  background: url(http://admin.shopkaire.com/static/media/kaire_logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 120px;
  border: 1px solid #fff;
}

.loader-back {
  position: fixed;
  z-index: 9999;
  background: #16356a;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}

.Loader::after {
  content: attr(data-text);
  color: #CECECE;
  color: var(--text-color, #CECECE);
  position: absolute;
  left: 50%;
  top: calc(100% + 20px);
  transform: translate(-50%, -50%);
  font-size: calc(70% + 0.10vw);
  text-transform: uppercase;
  letter-spacing: 5px;
}

span.name_d0 {
  justify-content: flex-end;
}

.icon-name span.name_d0 svg:first-child {
  opacity: 0;
}

.MuiModal-root {
  z-index: 9999 !important;
}

.orer-details-tables th {
  background: #ddd;
}

/* 01-07-2022 */
.statis_datas .commsion-weekly-dashboard .dashboard-table-commsion table.inner-table tbody tr:not(:last-child) td {
  border-bottom: 0;
}

/* .container.order-detail-page .groups.tabs ul.tabs {
    max-width: calc(100% - 67px);
  } */

.saved-new-card img {
  max-width: 60px;
  object-fit: contain;
  height: 30px;
}

.Level .istviewsection {
  padding-top: 10px;
}

/* .Level {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
} */

.Level .row:last-child {
  padding-bottom: 10px;
}


/* 04-07-2022 */
[class*="Downlinestyle__Downline"] {
  float: left;
  width: 100%;
}


.flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: fixed;
  z-index: 999;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  left: 50%;
  justify-content: center;
}

.flexbox>div {
  width: 300px;
  height: 300px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: auto;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.bt-spinner {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: transparent;
  border: 4px solid #ddd;
  border-top-color: #00356a;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}


.flexbox:before {
  content: "";
  width: 100%;
  height: 100vh;
  background: rgb(255 255 255 / 50%);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body.bg-salmon [class*="NavBarStyle-sc"] {
  visibility: hidden;
  opacity: 0;
}

.bg-salmon .fotter_section {
  display: none;
}


@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.table_container {
  border: 1px solid #ddd;
  border-radius: 30px;
  overflow-x: hidden;
  margin-bottom: 30px;
}

table.Cart_product.whistlist-table {
  width: 100%;
  max-width: 100%;
  text-align: left;
  margin-bottom: 0;
}

table.Cart_product.whistlist-table tr:not(:last-child) td {
  border-bottom: none
}

table.Cart_product.whistlist-table td:not(:last-child) {
  border-right: none
}


table.Cart_product.whistlist-table tr td {
  padding: 15px 20px;
}

table.Cart_product.whistlist-table th {
  text-align: left !important;
}

.bg-footer {
  overflow: hidden;
}

/* 18-08-2022 */
.thnku-sec .Cart_product .table-wrap+.row {
  margin-top: 10px;
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.container.checkout-main.checkout_main_section_class :where(h4, strong) {
  font-family: 'Graphik-bold';
  font-weight: 500;
}

.main_checkout_autoship_main_dialog .check_out_main p {
  font-size: 16px;
  line-height: 1.5;
}

.thnku-sec .Cart_product {
  margin-bottom: 40px;
}

.container.order-detail-page .groups.tabs ul.tabs .col-md-6 button {
  margin: 0;
}

.mainorder-detail-sec .container.order-detail-page .react-share__ShareButton {
  padding: 0 !important;
}

.autoship_order_detail .autoship_details_table .table-responsive .qty-sec #qty {
  min-width: auto;
}

.autoship_order_detail .autoship_details_table .table-responsive .qty-sec #qty :where(.sub, .add) {
  padding: 0 !important;
  width: 35px !important;
  max-width: 35px !important;
}



@media (max-width: 767px) {
  .dialog_scrollClass .MuiPaper-root {
    margin: 0;
  }

  .upper_lower_div ul.navbar-top li:first-child a {
    padding-left: 0;
  }

  div ul.nav.navbar-nav.navbar-top li>a {
    padding: 0 0 0 0;
  }

  div .upper_lower_div ul.navbar-top li span {
    color: #fff;
    padding: 0 10px;
  }

  ul.nav.navbar-nav.navbar-right .dashboard_div a {
    padding: 0;
  }

  .product_cat {
    padding: 50px 0 10px;
  }
}



/* 21-07-2022 */

.banner-video {
  position: relative;
  height: 650px;
  overflow: hidden;
  float: left;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 70px;
}

.banner-video iframe {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 130vw;
  height: 160%;
}

pycnogenolstyle .container {
  margin: 60px auto;
  clear: both;
}

.container-main {
  max-width: 1440px;
  margin: 0 auto;
  display: block;
  clear: both;
}

.add_address_from_CheckoutPage_main_dialog .form-check {
  display: flex;
  align-items: center;
}

.add_address_from_CheckoutPage_main_dialog .form-check input[type="checkbox"] {
  height: auto;
  line-height: normal;
  margin-top: 0;
}

.add_address_from_CheckoutPage_main_dialog .row {
  margin: 0;
}


.add_address_from_CheckoutPage_main_dialog .select:after {
  top: 20px !important;
}

.add_address_from_CheckoutPage_main_dialog .form-check {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}


.add_address_from_CheckoutPage_main_dialog p.form-check-label {
  margin-bottom: 5px;
}

.order_table.address-list.auto-ship-order-table a {
  color: #fff;
  background: var(--blue);
  padding: 10px 15px;
  border-radius: 30px;
  border: 2px solid var(--blue);
  text-decoration: none;
  margin: 0 auto;
  transition: 0.3s ease all;
}

.order_table.address-list.auto-ship-order-table a:hover {
  background: #fff;
  color: var(--blue);
}

.show_on_hover .tree_top_details .icons a {
  color: #fff;
}

.show_on_hover .tree_top_details .icons {
  display: flex;
  justify-content: flex-end;

}

.show_on_hover .tree_top_details {
  background: var(--blue);
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.show_on_hover .data svg {
  color: var(--blue);
  fill: var(--blue);
}

.show_on_hover .data .icons {
  line-height: 0;
}

.signupandforget {
  margin-top: 20px;
  display: flex;
  width: 100%;
}

form.signupform.main-sign-frm button.btn.btn-primary.sigup {
  background: none;
  color: var(--blue);
  text-transform: uppercase;
  border: none;
  padding: 0;
  text-align: left;
  max-width: initial;
}

.signupandforget>* {
  flex: 1;
  align-items: center;
}

.signupandforget .forgot.password {
  padding: 0;
  text-align: right;
}

.thn-lft h3 {
  margin: 7px 0 0;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Graphik';
}

.thn-lft .row {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.thn-lft .row::before {
  display: none;
}

.thn-lft h4 {
  background: #16356b;
  padding: 10px 18px;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
}

.thn-lft {
  position: relative;
  overflow: hidden;
  z-index: 9;
}


.mian-thank h2 {
  width: 100%;
}

.mian-thank svg.bi.bi-check-circle {
  width: 90px;
  height: 90px;
  fill: #27a200;
}

.mian-thank {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.container-main h3 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
}

.comistion-table-data.dashboard-table-commsion table.MuiTable-root.inner-table.css-rqglhn-MuiTable-root {
  background: #eaf2ff;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.css-1ygcj2i-MuiTableCell-root:empty,
.MuiTableRow-root td.MuiTableCell-root.MuiTableCell-body:not(.MuiCollapse-vertical td.MuiTableCell-root.MuiTableCell-body):nth-child(1):empty {
  display: none;
}

.MuiCollapse-wrapperInner.MuiCollapse-vertical [aria-label="purchases"] tr.MuiTableRow-root td {
  padding-left: 28px;
}

.MuiCollapse-wrapperInner.MuiCollapse-vertical [aria-label="purchases"] tr.MuiTableRow-root td:nth-child(3) {
  padding-left: 38px;
}

.Kosher a {
  display: flex;
  max-width: 100px;
  justify-content: center;
  margin: 10px auto 0;
  color: #000;
}

.Kosher a img {
  width: 100%;
  max-width: 60px;
  height: 60px;
}

a.par-logo span {
  width: 25px;
  height: 25px;
  background: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  font-weight: 900;
  margin: 0 auto 5px;
}

.Kosher a.par-logo p {
  font-size: 14px;
}

.slider-main .content-product>svg {
  font-size: 40px;
  color: #ccc;
}

.product-main:hover .content-product>svg {
  color: var(--blue);
}

.product-main:hover .content-product {
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

.content-product {
  transform: translateY(-100px);
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease all;
  transform-origin: top;
}

.table-wrap table th {
  background: rgb(6, 53, 106);
  color: rgb(255, 255, 255);
  text-align: left;
  padding: 20px;
  font-size: 15px;
  text-transform: capitalize;
}

.table-wrap+.row {
  margin-top: 10px;
  border: none;
}

.table-wrap table {
  width: 100%;
  border: 1px solid #eaeaea;
}

.table-wrap tr.productDetailTable:nth-child(2n) {
  background: #f5f5f5;
}

.table-wrap tr.productDetailTable td {
  padding: 20px;
  font-size: 15px;
}

.cart-product-name {
  font-weight: 700;
}

[aria-label="Ships now & after 28 days"] {
  background: var(--blue) !important;
  color: #fff !important;
}

.crossImg .close_button {
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
}

.crossImg .close_button svg.MuiSvgIcon-root {
  color: var(--blue);
  width: 25px;
  height: 25px;
}

/* 09-08-2022 */
.order_details_main_dialog h3 {
  background: var(--blue);
  color: #fff;
  padding: 10px;
  font-size: 22px;
}

.order_details_main_dialog .close_button {
  right: 10px;
  padding: 0;
  top: 18px;
}

.check_out_main .check_out {
  margin: 40px 0 20px;
}

.check_out_main .check_out,
.check_out_main .check_out-btn {
  text-align: center;
}

.undefined_main_dialog .check_out-btn button {
  background: none;
  color: var(--blue);
}

.check_out_main button {
  font-size: 16px;
  color: #fff;
  border-radius: 30px;
  padding: 10px 20px;
}

/* 16-08-2022 */
.groups.tabs .transactions-data-table table.MuiTable-root button.MuiButtonBase-root {
  padding: 8px 15px !important;
}

.order-history table.commission-table th:last-child {
  width: 270px;
  max-width: initial;
}

body .mainorder-detail-sec .container.order-detail-page button {
  font-size: 14px;
  height: 36px;
  width: 100% !important;
  margin: 0 10px;
  max-width: max-content;
  align-items: center;
  justify-content: center;
  line-height: 0;
  padding: 10px 28px !important;
  white-space: nowrap;
}

/* body .mainorder-detail-sec .container.order-detail-page button:hover {
  background-color: #fff !important;
  color: #06356a !important;
} */

body .mainorder-detail-sec .container.order-detail-page .disable {
  pointer-events: none;
}

.main_order_section_class table .title12 {
  width: 100% !important;
}

.icon-box-cart svg {
  margin-top: 2px;
}

/* [class*="FooterStyle-sc"] {
  position: absolute;
  bottom: 0;
  width: 100vw;
  z-index: 99;
} */

.main_div {
  /* padding-bottom: 430px; */
  /* padding-bottom: 530px; */
}

.fotter_section {
  width: 100vw;
}

body {
  position: relative;
  min-height: 100vh;
}

.order_table.address-list.order-list tbody tr td .edit_address button {
  padding: 0 !important;
  margin: 0 10px !important;
  font-size: 15px;
}

.main-invitaion table.comistion-table-data.dashboard-table-commsion.address-list thead th.year_block {
  padding: 15px 10px;
}

.not-check+.not-check span.eye_icon_main_class {
  right: 0;
}



/* 04-10-2022 */
.logo img {
  width: 100%;
  max-width: 150px;
  margin-left: 0;
  margin-right: auto;
}

.logo {
  margin-bottom: 20px;
  text-align: right;
}

.fotter_section .logo img {
  margin: 0 auto;
  max-width: 260px;
}

.fotter_section .logo {
  text-align: center;
  background: #fff;
  display: inline-flex;
  padding: 6px 10px;
  /* border: 4px solid var(--blue); */
}


.banner-product-newimage img {
  max-width: 200px;
}

.banner-product-newimage {
  position: absolute;
  bottom: 200px;
  left: 50%;
  transform: translateX(-50%);
}

.paymentform_from_savedCardComp_main_dialog h2.dialog_title_for_all {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

@media(min-width: 1920px) {
  .banner-video iframe {
    height: 260%;
  }
}

@media (max-width: 991px) {
  .banner-video {
    margin-top: 72px;
    margin-bottom: 20px;
    padding-bottom: 40%;
    height: auto;
  }

  pycnogenolstyle .container {
    margin-top: 0;
  }

  .product-main .price_section div {
    font-size: 14px;
  }

  .product-main .title_section h3 {
    font-size: 13px;
    margin-top: 10px;
  }

  .title_section p {
    font-size: 14px;
    line-height: normal;
  }

  button.btn.btn-larn {
    font-size: 15px;
  }

  .row.cupon_and_kiare_section .col-md-4 {
    max-width: calc(100%/2);
    width: 100%;
  }

  .container.order-detail-page .groups.tabs ul.tabs {
    max-width: 100%;
    margin-top: 0 !important;
    justify-content: center;
  }

  .home-page>.slider-main {
    margin: 50px 0 10px;
  }

  .silck-slide-home .slick-slide {
    padding: 0 5px;
  }

  .main_div {
    /* padding-bottom: 570px; */
  }

  pycnogenolstyle .banner-video {
    margin-bottom: 30px;
  }

  .mini-cart-sec .Cart_product {
    min-width: auto;
  }

  .main_pagination_section_class {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .paymentform_from_savedCardComp_main_dialog h2.dialog_title_for_all {
    margin-bottom: 0;
  }

  .main_pagination_section_class {
    margin-top: 0px;
  }

  [class*="FooterStyle-sc"] {
    position: unset;
  }

  .main_div {
    padding-bottom: 0;
  }

  pycnogenolstyle .container {
    margin: 0 auto 30px;
    float: left;
    width: 100%;
  }

  .container-main h3 {
    line-height: normal;
  }

  .banner-video {
    margin-bottom: 20px;
    margin-top: 70px;
    padding-bottom: 60%;
  }

  .mini-cart-sec .container .mini-cart .mini-cart-des {
    position: relative;
    padding-right: 20px;
  }

  .mini-cart-sec .container .mini-cart .mini-cart-des>.cart-product-details:first-child {
    word-break: break-all;
  }

  .mini-cart-sec .container .mini-cart .mini-cart-des .main-qty-sec .box {
    justify-content: center;
  }

  .export-to-xls {
    margin-right: 10px;
  }

  .mainorder-detail-sec .container.order-detail-page .row {
    margin: 0;
  }

  .row.cupon_and_kiare_section .col-md-4 {
    max-width: 100%;
    width: 100%;
  }

  .row.cupon_and_kiare_section .col-md-4.kairecassh {
    margin-top: 30px;
  }

  .container.checkout-main h1.title {
    font-size: 22px;
  }

  .cart-now-main {
    overflow-x: auto;
    width: 100%;
  }

  .container.order-detail-page h1 {
    font-size: 18px;
  }

  .container.order-detail-page {
    padding: 0;
  }

  .row.cupon_and_kiare_section {
    margin: 0;
  }

  .mainorder-detail-sec div.get_commision {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }

  .profile-btn {
    flex-wrap: wrap;
    gap: 10px;
  }

  .mainorder-detail-sec .row.addres-page {
    float: none;
    margin: 0;
  }

  .groups.tabs {
    margin-top: 15px;
  }

  .mainorder-detail-sec .container.order-detail-page button.MuiIconButton-sizeSmall {
    padding: 0px 20px !important;
  }

  .right-side h4 {
    font-size: 25px;
  }

  .table-wrap table {
    width: 160vw;
  }

  .home-page>.slider-main {
    margin: 30px 0 0;
  }

  .order_details {
    max-width: 100%;
  }

  .thn-lft .row>[class*="col-md"] {
    max-width: 100%;
    width: 100%;
    flex: 100%;
  }

  .thn-lft h3 {
    font-size: 14px;
  }

  .thnkyou-page h3 {
    margin-top: 5px;
  }

  .thn-lft h4 {
    font-size: 20px;
  }

  .thnkyou-page {
    margin: 30px auto;
  }

  .main_saved_card_class .savedClass {
    width: 97%;
    max-width: 100%;
    flex: 100%;
  }

  .container.checkout-main button {
    line-height: normal;
    font-size: 13px;
  }

  .slider-main .carousel-inner {
    overflow: visible;
  }

  [class*="CheckoutStyle-sc"] form .container {
    padding: 0;
  }
}

span.discount_smartship {
  position: absolute;
  right: 0;
  text-align: right;
  padding-right: 20px;
}

.title_section>.green_code {
  position: absolute;
  bottom: 10px;
  left: 0;
  padding-left: 10px;
  color: green;
  font-size: 12px;
  max-width: max-content;
  font-family: 'Lato';
}

.normal_radio_container.radio_class.custom_radio {
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
}

.normal_radio_container.radio_class.custom_radio.add_to_the_class {
  padding-bottom: 30px;
}

.product-main .selected_class .title_section>.green_code {
  bottom: 10px;
  margin: 0;
}

.product-main span.discount_smartship {
  padding-right: 20px;
}

.down_icon {
  font-size: 37px;
}

.category_table span.discount_smartship {
  position: absolute;
  width: max-content;
  right: 15px;
  bottom: 8px;
}

.price_section span.discount_smartship {
  max-width: max-content;
  position: absolute;
  right: 15px;
}

span.error.deletemsg {
  max-width: 580px;
  margin: 0 auto;
  background: #ffe8b3;
  padding: 7px 40px;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.product-firxt span.discount_smartship {
  font-size: 12px;
  bottom: 10px;
}

.next-sec label {
  margin-bottom: 0;
  color: var(--blue);
}

.add_product_button {
  display: flex;
  justify-content: space-between;
}

.date-picker input[type="date"] {
  border: none;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 12%);
  padding: 6px 5px;
  color: var(--blue);
}

.action_button_section {
  flex-wrap: wrap;
}

.action_button_section span.error {
  margin-bottom: 20px;
  font-size: 14px;
}

.check_out_main [class*="check_out"] {
  text-align: center;
  padding-left: 139px
}

/* 13-09-2022 */
.shipping_details>div {
  padding-left: 10px;
}

.container.order-detail-page h1 {
  height: 55px;
}

.table_container {
  border-radius: 0;
}

.Cart_product.order-detail-sec .main_order_detail_class .autoship_order_detail .add_product_button .add-product-autoshiporder {
  margin-left: 0;
}

.row.addres-page button.add-address svg {
  width: 13px;
  height: 13px;
  margin-left: 5px;
  margin-top: -3px;
  transform: translateY(2px);
}

.undefined_main_dialog .check_out_main [class*="check_out"] {
  padding-left: 0;
}

/* 12-09-2022 */
.account-creation-section [class*="ViewCartPopstyle"] .ic-pc-container {
  -webkit-box-shadow: 0 1px 8px rgb(56 65 74 / 15%);
  box-shadow: 0 1px 8px rgb(56 65 74 / 15%);
  padding: 20px;
  max-width: calc(100%/2 - 13px);
  width: 100%;
  flex: 100%;
}


.account-creation-section {
  max-width: 560px;
  width: 100%;
}

.account-creation-section .ic-pc-container .subtitle {
  padding-bottom: 10px;
}

.account-creation-section .heading {
  margin-top: 0;
}

body .mainorder-detail-sec .container.order-detail-page .promoter_pack_container>button {
  max-width: 100%;
  font-size: 14px;
}

.order_table.address-list.order-list tbody tr td.action-btn:before {
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: 0;
  top: 0;
  justify-content: center;
}

.container.order-detail-page td.action-btn .title12 {
  width: 100%;
  max-width: 230px;
  display: inline-flex;
}

.changeAdd_auto_md {
  padding: 0;
}

.login-section-button {
  display: flex;
}

.order_table.address-list.order-list.auto-ship-order-table.smartship button {
  /* padding: 0px 5px 0 !important; */
  padding: 0px !important;
  margin: 0 5px !important;
  height: auto;
}

.order_table.address-list.order-list.auto-ship-order-table.smartship button:hover {
  background-color: transparent !important;
}

.order_table.address-list.order-list.auto-ship-order-table.smartship button svg {
  margin: 0;
}

.savedCard_detail {
  width: 100%;
  max-width: calc(100% - 35px);
  margin: 0 auto;
}

.next-sec {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px 16px;
  background: #f7f7f7;
  padding: 10px;
}

.main_checkout_autoship_main_dialog .check_out_main [class*="check_out"] {
  padding-left: 0;
}

.row.cupon_and_kiare_section .row {
  margin: 0 !important;
}

.checkout-addrees svg {
  fill: var(--blue);
}

.container.order-detail-page .customer_smartship .heading-main-text h1 {
  font-size: 20px;
}

/* 14-09-2022 */
.left-side-part ul li:last-child {
  border-bottom: 0;
}

.coupon_list_table td a {
  font-weight: 600;
  color: var(--blue);
}

.d-flex {
  display: flex;
  gap: 10px;
}

.main-button-group .d-flex .re-order {
  margin: 0 !important;
}

.radio_section_for_shipping_cost_gsn {
  padding: 0 15px !important;
  text-align: center;
}

.autoship_order_detail {
  padding: 0 15px;
}

.Cart_product.order-detail-sec .main_order_detail_class .autoship_address_section .thn-lft.raido-addres-sel .radio-bnt .checkout-addrees {
  padding-top: 0 !important;
}

.main_shipping_method_class {
  padding: 10px 18px 0;
}

.main_shipping_method_class .radio_section_for_shipping_cost_gsn {
  padding: 0 !important;
}

.mainorder-detail-sec .backbutton {
  width: 100%;
  text-align: center;
  padding: 0 40px 20px;
}

.mainorder-detail-sec .backbutton .back-button {
  margin: 0 !important;
}

.transactions-detail-sec.mainorder-detail-sec .date_range_filter input[type="radio"] {
  margin-right: 5px;
  margin-top: -3px !important;
}

.profile-text-right .form-group :where(.show-email-check, .show-phone-check) {
  top: 39px;
  justify-content: flex-end;
  left: 0;
}

.container.order-detail-page.main_address_page_listing .add-address-sec .edit_address>svg {
  font-size: 16px;
  transform: translateY(5px) !important;
}

.row.addres-page.dashboard_main_div .left-side-part {
  max-height: 897px;
  overflow-y: auto;
}

.not-found .not_found_class span:empty {
  display: none;
}

tr.not-found td:empty {
  display: none;
}

.dialog_scrollClass .close_button {
  padding: 0 !important;
  right: 13px;
  top: 15px;
  width: 40px;
  border-radius: 0 !important;
}

/* .box:hover+.details-list-section.hide {
  display: block !important;
} */

.comission-data-table.comission-data-t2 table td {
  padding: 5px;
}

.headsection-graphical .common-static-left h3.title1 {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}

.headsection-graphical .common-static-right a {
  color: #00356a;
  text-decoration: none;
  font-weight: 600;
}

.leftsec>.not-check {
  padding-left: 10px;
}

.mainorder-detail-sec .container.order-detail-page .link_buttton button {
  margin: 0;
}

.main-invitaion table.comistion-table-data.dashboard-table-commsion.address-list {
  border: 1px solid #ddd;
}

.main-invitaion .comistion-table-data.dashboard-table-commsion td {
  font-size: 14px;
}

.main-cstm {
  padding: 0 25px;
}

table.comistion-table-data.dashboard-table-commsion.address-list {
  margin: 15px 0 0;
}

.container.order-detail-page [class*="Downlinestyle__Downline"] .groups.tabs ul.tabs .col-md-6 {
  width: 100%;
  max-width: 130px;
  margin-right: 0;
}

.export-to-xls+.groups.tabs ul li {
  max-width: initial;
  width: 100%;
}

.promoters.in.downline_section {
  justify-content: flex-start;
  max-width: 350px;
}

.non_active a svg {
  font-size: 14px;
  color: #fff;
}

.non_active a:first-child {
  margin-right: 10px;
}

.non_active a {
  background: var(--blue);
  color: #fff;
  padding: 0px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

form.invitation-link-main input[type="text"] {
  height: 40px;
  box-shadow: none;
}

form.invitation-link-main .field-class label {
  text-align: left;
  font-size: 14px;
  text-decoration: none;
}

.saved_card_section:empty {
  display: none;
}

[class*="PayCardSection"] .main_saved_comp button {
  margin: 10px 0;
  font-size: 14px;
  padding: 10px 20px;
  height: 40px;
  line-height: normal;
}

.shipping_payment .main_shipping_method_class {
  padding: 0;
}

select option {
  font-family: var(--common-font);
}

[class*="ReFundStyleComp__RefundFilterForm"] .date_range_filter input[type="radio"] {
  transform: translateY(-2px);
}

.address-list .right-side-contents tr :where(.active_bhoot, .non_active) {
  background: #dddddd26;
}

.new.customers.on.autoship_section span:first-child svg {
  transform: translateY(2px);
}

.kaire_conatiner.common_section_class {
  border: none !important;
}

table th {
  text-transform: capitalize;
}

.thn-lft.thn-rgt.raido-addres-sel :where(th, td) {
  padding: 8px 13px;
}

.main-invitaion .comistion-table-data.dashboard-table-commsion :where(th:nth-child(2), td:nth-child(2)) {
  width: 120px;
  word-break: break-word;
}

select#page {
  width: 100%;
  border: 1px solid #ddd;
  padding: 7px;
  border-radius: 3px;
  height: 40px;
}

button.btn.btn-primary.signup_up_button,
.main-invitaion a.btn.btn-primary {
  background: var(--blue);
  border-radius: 30px;
  height: 40px;
  text-transform: uppercase;
  border: 2px solid var(--blue);
  max-width: 150px;
}

button.btn.btn-primary.signup_up_button:hover {
  background: #fff;
  color: var(--blue);
}

.submit_button.field-class {
  margin: 20px 0 10px;
}

.css-1480iag-MuiInputBase-root-MuiInput-root::after,
.css-1480iag-MuiInputBase-root-MuiInput-root:before {
  display: none !important;
}

.share_coupan_main_dialog .autocompleteSection label#disable-clearable-label {
  font-size: 20px;
  line-height: normal;
  font-family: var(--common-font);
  font-weight: 600;
  color: #000;
  position: unset;
  margin-bottom: 0;
}

.MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary {
  margin-top: 0;
}

.quary_constainer table thead th:nth-child(4) {
  width: 650px;
}

.left-commison-sec,
.right-commison-sec {
  display: flex;
  align-items: center;
}

.not-found {
  display: none;
}

td.not_found:empty,
ul.dropdown-menu-wishlist.dropdown-popup_viewCart:empty {
  display: none;
}

.main-invitaion a.btn.btn-primary {
  font-size: 12px;
  max-width: 130px;
  display: flex;
  align-items: center;
  transition: 0.3s ease all;
}

.main-invitaion .comistion-table-data.dashboard-table-commsion tr td:last-child {
  min-width: 240px;
}

.main-invitaion table.comistion-table-data.dashboard-table-commsion.address-list button {
  margin: 0 5px;
}

.usa_country {
  display: flex;
  gap: 10px;
}

.broughtby span {
  text-transform: capitalize;
  font-family: 'Graphik-bold';
}

.usa_country button {
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 12px;
  font-family: 'Graphik-bold';
}

.cart-sec.main_viewCart_section_class .cart-heading h1 {
  text-transform: capitalize;
}

.noThanks {
  text-align: center;
}

.noThanks button.btn.btn-primary {
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  color: #fff;
  max-width: fit-content;
  width: 100%;
  border-radius: 25px;
  border: 2px solid var(--blue);
  transition: 0.3s ease all;
  background-color: var(--blue);
  padding: 10px 30px;
  font-size: 16px;
  transition: 0.3s ease all;
}

.noThanks button.btn.btn-primary:hover {
  background: #fff;
  color: var(--blue);
}



.cstm-right-drawer .MuiBox-root li.MuiListItem-root {
  font-size: 18px;
}

.cstm-right-drawer ul.MuiList-root.MuiList-padding {
  border-bottom: 0;
}

.cstm-right-drawer .MuiBox-root li.MuiListItem-root .MuiListItemText-root {
  font-size: 20px;
}

.cstm-right-drawer ul.MuiList-root.MuiList-padding li.MuiListItem-root:not(:last-child) {
  border-bottom: 1px dotted #ddd;
}

.cstm-right-drawer .MuiBox-root li.MuiListItem-root .MuiListItemText-root span.MuiTypography-root {
  font-size: 14px;
  font-family: 'Graphik';
}

.thn-lft h4 {
  font-size: 18px;
  text-align: left;
  margin: 0;
  border-radius: 0;
}

.payment_section .main_saved_comp {
  margin-bottom: 30px !important;
  padding: 20px 15px !important;
}



.thn-lft .row {
  gap: 0;
}

.thn-lft .row>[class*="col-md"] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border-radius: 0;
}

.thn-lft .row>[class*="col-md"]>div,
.thn-lft h3 {
  margin: 0;
  padding: 15px;
  max-width: 50%;
  border-bottom: 1px solid #ddd;
  flex: 50%;
  line-height: normal;
}

.section_second .extra {
  width: 100%;
  max-width: calc(100%/3 - 15px);
  padding: 0;
  margin-top: 0;
  flex: auto;
}

.form-wrappper.extra {
  margin-bottom: 0;
}

select.form-control {
  border-radius: 30px;
  height: 40px;
}

.thn-lft .row>[class*="col-md"]>div {
  border-right: 1px solid #ddd;
  text-transform: capitalize;
}

.thn-lft h3 {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  /* max-width: 50%;
  flex: 50%; */
}

[class*="WallatStyleComp__FormStyleWallet"]+.groups.tabs .tabs [class*="col-md"] {
  max-width: 150px;
  width: 100%;
}

.main-invitaion table.comistion-table-data.dashboard-table-commsion.address-list button[aria-label="copy"] {
  width: 32px;
  height: 32px;
  max-width: 32px;
  display: flex;
  padding: 0 !important;
  background: var(--blue);
  color: #fff;
}

.main-invitaion .comistion-table-data.dashboard-table-commsion tr td[data-value="Share"] {
  display: flex;
}

/* end of 15-09-2022 */

/* start 16-09-2022 */
.transactions-data-table table.MuiTable-root td button {
  margin-left: 0 !important;
}

.btn-sec-cstm .d-flex button {
  margin: 0 !important;
}

.view_cart_action {
  text-align: center;
}

.view_cart_action button {
  border-radius: 30px;
  background: var(--blue);
  color: #fff;
  padding: 4px 15px;
  font-family: var(--common-font) !important;
  font-size: 12px !important;
  border: 2px solid var(--blue);
}

.view_cart_action button:hover {
  color: var(--blue);
}

.thn-lft.raido-addres-sel .table.inner-table-shop :where(th, td) {
  padding: 8px 20px;
}

.row.order-cstm.addressesOption_with_change .thn-lft.raido-addres-sel td {
  text-transform: capitalize;
}

.savedCard_detail .main_saved_comp .saved_card_section {
  border: 1px solid #ddd;
  margin: 20px 0;
}

.mainorder-detail-sec .container.order-detail-page .main_order_detail_class .savedCard_detail button.add_new_card {
  margin-left: 0 !important;
}

.downline_users {
  overflow-x: auto;
}

.lavel-box .Level h2 {
  font-size: 20px;
}

.dropdown-popup_viewCart .view_cart_div {
  border-right: 1px dotted #ddd;
  padding-right: 10px;
}

.dialog_scrollClass .order_details_main_dialog .close_button {
  top: 13px;
}

/* .coupon_list_table .active_amart_tbody tr.MuiTableRow-root {
  display: contents;
} */

[class*="PayCardSection"] .main_saved_comp .saved_card_section {
  border: 1px solid #ddd;
  padding: 0 20px;
}

.main_stapperForm .container.signUpReview_page .table_responsive table.productDetailTable.table_container th {
  padding: 10px 15px;
  background: var(--blue);
  color: #fff;
}

.totalAmount_section .Amountinner_div .main_shipping_method_class {
  padding-right: 0;
}

.container.order-detail-page.main_address_page_listing .cstm-btns-sec button.Save-address {
  margin-right: 0 !important;
}

[class*="DialogComponent__Dialog"] table {
  margin-top: 0;
}

.mini-cart-sec:before {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgb(0 0 0 / 60%);
  z-index: -1;
}

.product-main svg {
  width: 1.2em;
  height: 1.2em;
}

.cstm-right-drawer .MuiDrawer-paper h2 span {
  background: #fff;
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  width: 30px;
  color: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-family: var(--common-font);
}

.cstm-right-drawer .MuiDrawer-paper h2 {
  margin: 0;
  /* border-bottom: 1px solid #ddd; */
  padding: 10px 18px;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  background: var(--blue);
  position: relative;
  padding-left: 45px;
}

.react-share__ShareButton[aria-label="whatsapp"] {
  background: #25d366 !important;
  width: 30px !important;
  height: 31px !important;
  max-width: 31px !important;
}

.react-share__ShareButton[aria-label="whatsapp"] svg {
  padding: 2px;
}

.main-invitaion table.comistion-table-data.dashboard-table-commsion.address-list button[aria-label="copy"] svg {
  font-size: 14px;
}

.main_sign_up_form_class .signupform.main-sign-frm .form-wrap svg {
  position: unset !important;
}

.form-wrap {
  position: relative;
}

.form-wrap .icon-content>div {
  width: 25px;
  height: 25px;
  background: var(--blue);
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_sign_up_form_class .signupform.main-sign-frm .form-wrap svg {
  position: unset !important;
  font-size: 12px;
}

.form-wrap .form-control {
  padding-right: 140px;
}

.form-wrap .icon-content {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  width: 130px;
  gap: 10px;
  background: #fff;
}

.footer_text {
  max-width: 873px;
  background: #fff;
  padding: 20px 30px;
  border: 1px solid var(--blue);
  margin: 0px auto 30px;
  font-size: 13px;
  font-weight: 600;
  color: #06356a !important;
  box-shadow: 0 0 0 6px rgb(255, 255, 255);
  position: relative;
  overflow: hidden;
}

/* 
.footer_text::after,
.footer_text::before {
  content: "\F10C";
  position: absolute;
  right: -40px;
  top: 30px;
  font-size: 90px;
  font-family: 'FontAwesome';
  opacity: 9%;
}

.footer_text::before {
  left: -40px;
  right: auto;
  top: -70px;
} */


.distributer_tooltip .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom {
  font-size: 12px;
}

body {
  overflow-x: hidden;
}

/* end of 16-09-2022 */
.container.order-detail-page h2 {
  text-align: center;
  position: relative;
  bottom: 9px;
}

.dropdown-popup_viewCart .view_cart_action {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding: 10px;
  max-width: 100%;
  width: 100vw;
  left: 0;
}

.add_address_from_CheckoutPage_main_dialog [class*="CheckoutStyle__AddressPopUpStyle"] .container {
  padding: 0;
}

/* 20-09-2022 */
.Cart_product .table-wrap table {
  width: 100%;
}

.checkout-main.checkout_main_section_class .btn.btn-primary {
  max-width: 150px;
}

.main-order.d-flex .link_buttton {
  width: 100%;
  max-width: 170px;
}

.main-order.d-flex .link_buttton:first-child {
  max-width: 130px;
}

.Amountinner_div tr:nth-child(2) .main_shipping_method_class {
  padding: 0;
}

.container.order-detail-page.order-history .order_table.address-list.order-list table.commission-table td.action-btn {
  width: 100%;
  min-width: 380px;
  display: flex;
}

.update-details.main-profile+button.btn.btn-primary {
  margin-top: 20px;
}

.profile-img-left .update-details.main-profile .form-group {
  flex-wrap: wrap;
}

.update-details.main-profile form {
  border: none;
}

.update-details input {
  margin-bottom: 10px;
  box-shadow: none;
  /* text-transform: capitalize; */
  width: 100%;
  padding-left: 10px;
  border-color: #ddd;
}

.main_profile_section_class .profile-text-right .update-details .row>button {
  margin-left: 0 !important;
}

.mainorder-detail-sec .container.order-detail-page .row button.btn.btn-primary {
  margin-left: 0;
}

.update-details.main-profile {
  border-bottom: 1px dotted #ddd;
}

.main_profile_section_class .profile-text-right .update-details input[type="checkbox"] {
  width: auto;
}

.update-details.main-profile .col-md-12 {
  padding: 0;
}

.newrefshow.d-flex .referral-copy {
  max-width: 405px;
  width: 100%;
}

.submit_button.field-class button.btn.btn-primary.signup_up_button {
  margin-left: 0;
}

.invitation-form form.invitation-link-main .submit_button.field-class,
.invitation-form .heading-top-main.right-address {
  margin-bottom: 0;
}

.box-text-bnner p {
  margin: 0;
  background: #fff;
  padding: 20px;
  text-align: left;
  font-style: italic;
  font-size: 20px;
  border: 2px solid #f1f1f1;
  color: var(--blue);
}

.Content-banner h2 sup {
  font-size: 20px;
  font-weight: 500;
  bottom: 100%;
  top: -2em;
}

.profile-img-d {
  position: relative;
}

.customer-rank span {
  background: var(--blue);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  font-size: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  box-shadow: none;
  color: #FFF;
  font-weight: bold;
}

/* 17-10-2022 */
.MuiDialog-container.dialog_scrollClass [class*="DialogComponent__DialogStyle"] .MuiDialogContent-root.container_for_load_data {
  padding-top: 20px;
}

.login-pop {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  font-weight: 400;
}

.save-credit-check {
  display: flex;
  gap: 5px;
  align-items: center;
}

.save-credit-check input#save_credit_card {
  margin-top: 0;
}

/* 18 dec 2023 */
.order-history .order_table.address-list.order-list {
  overflow-x: auto;
}



@media (max-width: 1200px) {
  .container.checkout-main {
    margin: 100px auto 0;
  }

  .main-invitaion table {
    width: 100vw;
  }

  .main-invitaion {
    overflow-x: auto;
  }

  .table-wrap {
    overflow-x: auto;
  }
}


@media (max-width: 1024px) {
  .order-history .order_table.address-list.order-list {
    overflow-x: auto;
  }

  .order-history .order_table.address-list.order-list table {
    width: 120vw;
  }

  .welcome-text {
    min-height: 330px;
  }
}

@media (max-width: 769px) and (min-width: 768px) {
  .MobileLeftNav__DrawerStyle-sc-2is40-0 {
    display: none;
  }

}

@media (max-width: 991px) {

  body .mainorder-detail-sec,
  .cart-sec.saved_card_page {
    padding: 104px 0 85px;
  }

  .row.order-cstm [class*="col-md"]:first-child {
    margin-top: 20px;
  }

  .row.order-cstm [class*="col-md"] {
    padding: 0;
  }

  .Cart_product.order-detail-sec:empty {
    display: none;
  }

  .mainorder-detail-sec .container.order-detail-page .row {
    margin: 0;
  }

  .row.order-cstm [class*=col-md]:nth-child(2) {
    margin-top: 20px;
  }

  .welcome-text {
    min-height: 260px;
  }
}

@media (max-width: 767px) {
  .price_section span.discount_smartship {
    bottom: 7px;
  }

  .order_details_main_dialog .close_button {
    top: 12px;
  }

  .cart-sec.saved_card_page {
    padding: 75px 0 80px;
  }

  .mainorder-detail-sec .order-history.container.order-detail-page button {
    min-width: max-content;
    margin-left: 0;
  }

  .cart-heading {
    margin: 0 0 25px;
  }

  .mainorder-detail-sec.commision-now .col-md-10 {
    max-width: 100%;
  }

  .Cart_product.order-detail-sec {
    min-width: auto;
  }

  .container.order-detail-page.order-history .order_table.address-list.order-list thead th:last-child {
    min-width: 330px;
  }

  table.Cart_product.saved_card_table {
    margin-bottom: 0;
  }

  .Cart_product tbody tr,
  /* .transactions-data-table tbody tr, */
  .commission-table tbody tr,
  table.addressTable-table tbody tr,
  table.Cart_product.saved_card_table tbody tr {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 100%;
    border: 1px solid #ddd;
  }

  table.Cart_product.saved_card_table tbody tr,
  table.addressTable-table tbody tr {
    margin-bottom: 15px;
  }

  .main-invitaion table.comistion-table-data.dashboard-table-commsion.address-list tbody tr td,
  .Cart_product tr td,
  /* .transactions-data-table table tbody tr td, */
  .active_smartship_table_main_div table tbody tr td,
  table.Cart_product.saved_card_table tbody tr td,
  .commission-table tbody tr td,
  .orer-details-tables tbody tr td,
  .autoship_details_table tbody tr td,
  .order_table.address-list.order-list tbody tr td,
  .order_details_main_dialog [class*="ActiveStyleComp__DialogSection"] tbody tr td {
    position: relative;
    border: none;
    padding: 13px 10px 13px 150px !important;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    word-break: break-word;
    width: 100%;
  }

  .main-invitaion table.comistion-table-data.dashboard-table-commsion.address-list tbody tr td:before,
  .Cart_product tbody tr td:before,
  /* .transactions-data-table table tbody tr td:before, */
  .active_smartship_table_main_div table tbody tr td:before,
  table.Cart_product.saved_card_table tbody tr td:before,
  .commission-table tbody tr td:before,
  .orer-details-tables tbody tr td:before,
  .autoship_details_table tbody tr td:before,
  .order_table.address-list.order-list tbody tr td:before,
  .order_details_main_dialog [class*="ActiveStyleComp__DialogSection"] tbody tr td:before {
    content: attr(data-value);
    background: #00356a;
    color: #fff;
    width: 100%;
    padding: 10px;
    max-width: 140px;
    text-align: left;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
  }

  .commission-table tbody tr td.action-btn:before {
    width: 100% !important;
    max-width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .Cart_product thead,
  /* .transactions-data-table thead, */
  .active_smartship_table_main_div table thead,
  table.commission-table thead,
  .orer-details-tables thead,
  .commission-table,
  .main_address_page_listing table,
  .address-list .Cart_product,
  .Table-container.table-responsive {
    border: none !important;
  }

  .order_table.address-list.order-list.auto-ship-order-table {
    overflow: visible;
    border-radius: 0;
  }

  .main_pagination_section_class {
    padding-bottom: 30px;
  }

  .order_details_main_dialog [class*="ActiveStyleComp__DialogSection"] thead,
  .main-invitaion table.comistion-table-data.dashboard-table-commsion.address-list thead,
  .Cart_product thead,
  /* .transactions-data-table thead, */
  .active_smartship_table_main_div table thead,
  .Cart_product.order-detail-sec .orer-details-tables thead,
  table.Cart_product.saved_card_table thead,
  .commission-table thead,
  .autoship_details_table thead.row,
  .order_table.address-list.order-list thead {
    display: none;
  }

  .address-list {
    margin: 0px 15px 15px !important;
  }

  .commission-table tbody tr td:not(:last-child) {
    border-bottom: 1px solid #ddd;
    padding-right: 10px !important;
  }

  .order-history .order_table.address-list.order-list {
    margin: 0 auto !important;
    border: none
  }

  .order-history .order_table.address-list.order-list table {
    width: 100%;
    max-width: calc(100% - 15px);
    margin: 0 auto;
  }

  .order-history .order_table.address-list.order-list {
    overflow: visible;
  }

  .order_table.address-list.order-list.auto-ship-order-table.smartship tbody tr:not(.even-tr, .odd-tr),
  table.Cart_product.saved_card_table tbody tr.not-found {
    display: none !important;
  }

  .mainorder-detail-sec .order-history.container.order-detail-page td.action-btn button {
    font-size: 10px !important;
    margin-bottom: 10px !important;
  }

  .container.order-detail-page td.action-btn .title12 {
    padding-left: 10px;
  }

  .container.order-detail-page td.action-btn>button:nth-child(2) {
    margin-right: 10px !important;
  }

  .commission-table tbody tr td:last-child div {
    padding: 0;
    max-width: 50%;
    padding: 10px;
  }

  .row.order-cstm [class*="col-md"]:first-child {
    margin-top: 0;
  }

  .changeadress-button {
    display: flex;
    gap: 10px;
  }

  .commission-table tbody tr td[data-value="PRODUCT IMAGE"]:before {
    min-height: 60px;
    display: flex;
    align-items: center;
  }

  .commission-table tbody tr.odd-tr:nth-last-child(3),
  .order_table.address-list.order-list.auto-ship-order-table.smartship tbody tr:nth-last-child(3) {
    margin-bottom: 0;
  }

  .edit_address>svg {
    transform: unset !important;
    font-size: 20px;
  }

  .edit_address {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .mainorder-detail-sec .address_class_responsive .addres-page.dashboard_main_div .order-detail-page.main_address_page_listing .button-top button.add-address svg {
    max-width: 18px;
    height: 18px;
    margin-top: 0;
    width: 18px;
    min-width: 10px;
    transform: translateY(-1px);
  }

  .mainorder-detail-sec .address_class_responsive .addres-page.dashboard_main_div .order-detail-page.main_address_page_listing .button-top button.add-address {
    max-width: 100px;
    align-items: center;
    display: flex;
  }

  .dialog_scrollClass {
    padding: 0 15px;
  }

  table tbody tr.not-found {
    display: none;
  }

  .add_product_button {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .next-sec * {
    flex: 100%;
    text-align: center;
  }

  .account-creation-section [class*="ViewCartPopstyle"] .ic-pc-container {
    max-width: 100%;

  }

  .account-creation-section [class*="ViewCartPopstyle"] .ic-pc-button-label {
    display: flex;
  }

  .account-type-login {
    padding: 0;
  }

  .heading {
    font-size: 28px;
  }

  .btn-arbonne-primary {
    font-size: 14px;
  }

  .table-responsive {
    overflow: visible !important;
  }

  .mainorder-detail-sec .main_order_detail_class {
    margin: 0;
  }

  .backbutton.d-flex {
    display: flex;
    justify-content: center;
    gap: 15px;
  }

  .mainorder-detail-sec .container.order-detail-page .main_order_detail_class button.add_new_card {
    margin: 0 auto 10px !important;
    display: flex;
  }

  td.ship_amout:before,
  td.ship_text:before,
  .main_autoship_section_class .autoship_order_detail table td:empty {
    display: none;
  }

  .autoship_details_table tr:nth-last-child(-n+3) {
    font-weight: 700;
  }

  .mainorder-detail-sec .row.addres-page {
    padding: 0;
  }

  body.dashboard {
    overflow-x: hidden;
  }

  .saved_card_section .main_saved_card_class .label {
    margin-bottom: 20px;
  }

  form.signupform.main-sign-frm {
    padding: 35px 20px;
  }

  .signupandforget :where(button.btn.btn-primary.sigup, .forgot a) {
    font-size: 13px;
  }

  .sign-up-bnt {
    width: 100%;
  }

  .row.order-cstm [class*="col-md"] {
    padding: 0 15px;
  }

  .orer-details-tables tbody tr td input.product-qty {
    text-align: right;
  }

  .Cart_product.order-detail-sec {
    padding: 0 15px;
  }

  .order_table.address-list.order-list.auto-ship-order-table.smartship button {
    padding: 7px 5px 0 !important;
  }

  .container.order-detail-page .Cart_product.order-detail-sec {
    padding: 0;
  }

  .container.order-detail-page .Cart_product.order-detail-sec .changeadress-button {
    flex-wrap: wrap;
  }

  .autoship_details_table tbody tr:not(:last-child) td {
    border-bottom: 1px solid #ddd;
  }

  .autoship_details_table {
    border: none !important;
    margin-bottom: 0 !important;
  }

  /* .savedCard_detail {
  padding: 0 15px;
} */
  .thn-lft.raido-addres-sel table.table.inner-table-shop td {
    border-top: 1px solid #ddd;
  }

  .date_range_filter {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }

  .orer-details-tables {
    border: none;
  }

  .container.order-detail-page h1 {
    height: auto;
  }

  .container.order-detail-page .groups.tabs ul.tabs {
    padding-left: 15px !important;
    gap: 10px;
  }

  .container.order-detail-page .groups.tabs ul.tabs .col-md-6 {
    margin: 0 0px 10px;
  }

  .Custom_filter>div {
    margin: 10px auto !important;
  }

  .groups.tabs .transactions-data-table table.MuiTable-root button.MuiButtonBase-root {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .main-qty-sec .box:last-child #qty :where(.add, .sub) {
    padding: 0 10px !important;
  }

  .checkout-addrees svg {
    font-size: 24px;
  }

  .cart-now-main .cart-product-details>div {
    text-align: right;
  }

  .row.cupon_and_kiare_section,
  .row.cupon_and_kiare_section .col-md-4.kairecassh {
    margin-top: 0 !important;
  }

  .checkout_main_section_class .container.order-detail-page .row {
    margin: 0 !important;
  }

  .table_container {
    border: none;
    margin-bottom: 0;
  }

  span.Total,
  span.amount_paid {
    padding-right: 0;
  }

  .Cart_product tbody tr {
    margin: 5px 0;
    float: left;
  }

  .container.checkout-main button {
    height: 35px;
  }

  /* .transactions-data-table table tbody tr td:nth-child(1)::before {
    content: "DATE";
  }

  .transactions-data-table table tbody tr td:nth-child(2)::before {
    content: "CREDIT";
  }

  .transactions-data-table table tbody tr td:nth-child(3)::before {
    content: "DEBIT";
  }

  .transactions-data-table table tbody tr td:nth-child(4)::before {
    content: "AVAILABLE WALLET BALANCE";
  } */

  .transactions-detail-sec.mainorder-detail-sec .container.order-detail-page .groups.tabs .transactions-data-table table tbody tr td:nth-child(4) {
    font-size: 13px !important;
  }

  .Cart_product.whistlist-table tbody tr td:nth-child(1)::before {
    content: "Product image";
  }

  .Cart_product.whistlist-table tbody tr td:nth-child(2)::before {
    content: "product name";
  }

  .Cart_product.whistlist-table tbody tr td:nth-child(3)::before {
    content: "Variation";
  }

  .Cart_product.whistlist-table tbody tr td:nth-child(4)::before {
    content: "Quantity";
  }

  .Cart_product.whistlist-table tbody tr td:nth-child(5)::before {
    content: "Price";
  }

  .Cart_product.whistlist-table tbody tr td:nth-child(6)::before {
    content: "Action";
  }

  .active_amart_tbody tr.MuiTableRow-root {
    margin: 20px auto;
    display: flow-root;
  }

  /* .container.order-detail-page.p-4 .active_amart_tbody tr.MuiTableRow-root {
    margin: 10px auto;
    display: flow-root;
  } */
  .orer-details-tables tbody td:nth-child(1):before {
    content: "PRODUCT";
  }

  .orer-details-tables tbody td:nth-child(2):before {
    content: "BV";
  }

  .orer-details-tables tbody td:nth-child(3):before {
    content: "QTY";
  }

  .orer-details-tables tbody td:nth-child(4):before {
    content: "TOTAL";
  }

  .Cart_product.order-detail-sec th {
    display: none;
  }

  .Cart_product .autoship_address_section tbody tr {
    margin: 0;
  }

  .container_of_slider button.MuiButtonBase-root {
    background: none;
    ;
  }

  form.signupform.main-sign-frm {
    width: 100%;
    max-width: calc(100% - 20px);
    padding: 10px;
  }

  form.signupform.main-sign-frm .Custom_filter input[type="text"],
  form.signupform.main-sign-frm .Custom_filter input[type="tel"] {
    font-size: 12px;
  }

  .commission-table {
    width: 100% !important;
  }

  .commission-table .error-commision {
    padding: 10px 0 10px !important;
    justify-content: center;
  }

  .error-commision:before {
    display: none !important;
  }

  .commission-table td {
    margin: auto;
  }

  .refund-report-data {
    overflow: visible !important;
    max-width: 100%;
  }

  .commission-table .title_error {
    padding: 0 10px !important;
  }

  .export-to-xls {
    margin-top: 10px;
    margin-right: 0;
    width: 100%;
    max-width: 100%;
    text-align: center;
  }

  .mini-cart-sec .container .mini-cart .mini-cart-des .main-qty-sec .box {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .container.order-detail-page.p-4 [class*="Reportsstyle__ReportPageStyl"] .table_container>.address-list {
    width: 100%;
    margin: 0 auto !important;
  }

  .left-side-contents>div>span:first-child {
    position: absolute;
    left: 0;
    top: 0;
  }

  .left-side-contents>div {
    position: relative;
    display: flex;
  }

  .left-side-contents span:nth-child(2) {
    padding-right: 0;
    max-width: 230px;
    width: 100%;
    display: inline-block;
    padding-left: 20px;
  }

  .mainorder-detail-sec .dashboard_main_div.main_class_responsive .table-top .signupform.main-sign-frm {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }

  .signupform.main-sign-frm .Custom_filter>div {
    margin-top: 0 !important;
    max-width: 280px;
    width: 100%;
  }

  .transactions-data-table table tr td:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }

  .mainorder-detail-sec .date_range_filter input[type="radio"] {
    margin-right: 5px;
    margin-top: -1px !important;
    margin-left: 10px !important;
  }

  .signupform.main-sign-frm .Custom_filter>.date_range_filter+div {
    margin: 0px 0 15px !important;
  }

  .transactions-data-table {
    padding: 0 15px;
  }

  .mainorder-detail-sec table tr td button {
    margin: 0 !important;
  }

  .mainorder-detail-sec .date_range_filter input[type="radio"]:nth-child(2) {
    margin-left: 8px !important;
  }

  .signupform.main-sign-frm .Custom_filter .date_range_filter input[value="custom"] {
    margin-left: 0 !important;
  }

  .Custom_filter .date_range_filter {
    font-size: 14px !important;
  }

  .export-to-xls {
    margin-bottom: 10px;
  }

  .comission-data-table {
    padding: 0 15px;
  }

  .comission-data-table.comission-data-t2 td:empty {
    display: none;
  }

  .profile-text-right .form-group :where(.show-email-check, .show-phone-check) {
    top: 69px;
    justify-content: flex-end;
    left: 0;
  }

  .update-details form .col-md-12 {
    padding: 0;
  }

  .update-details .form-group {
    flex-wrap: wrap;
  }

  .main_profile_section_class .profile-text-right .update-details form input {
    padding: 0 !important;
  }


  .main-button-group .d-flex {
    flex-wrap: wrap;
    gap: 0;
  }

  .mainorder-detail-sec .container.order-detail-page .main-button-group .d-flex button {
    max-width: 100% !important;
    flex: 100% !important;
    margin: 0 !important;
  }

  .main-button-group .d-flex {
    padding: 10px 70px 20px;
    row-gap: 10px;
  }

  .container.order-detail-page.main_address_page_listing .add-address-sec .edit_address>svg {
    transform: translateY(0px) !important;
  }

  .Cart_product.saved_card_table tr td:not(:last-child),
  .add-address-sec .order_table.address-list.order-list .addressTable-table tr td:not(:last-child),
  .order_table.address-list.order-list tbody tr td:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }

  .mainorder-detail-sec .container.order-detail-page .autoship-action button svg {
    margin: -4px 0 0;
  }

  .next-sec {
    margin: 10px 0 0;
    flex-wrap: wrap;
  }

  .main_autoship_section_class.SmrtShip_Orders .back-button {
    height: 35px !important;
  }

  .main_autoship_section_class.SmrtShip_Orders .table-responsive {
    padding: 0 !important;
  }

  .main_autoship_section_class.SmrtShip_Orders .table-responsive tr:nth-last-child(-n+3) {
    margin: 0;
  }

  .main_autoship_section_class.SmrtShip_Orders .table-responsive tr,
  .commission-table tbody tr,
  .Cart_product.saved_card_table tr {
    border: 1px solid #ddd;
  }

  .ship_text {
    border-bottom: 1px solid #ddd !important;
  }

  .savedCard_detail {
    max-width: 100%;
  }

  .autoship_address_section.billing-arcstm .thn-lft.raido-addres-sel h2 {
    text-align: center;
  }

  .autoship_order_detail.SmartShip-Order-Details .radio_and_card_type_container {
    min-width: 80px !important;
  }

  .saved-card .add_new_card {
    margin: 20px auto !important;
    display: flex;
  }

  .payment_form_section .payment_fields .row [class*="col-md"] {
    padding: 0;
  }

  .signupform.main-sign-frm .Custom_filter>div {
    line-height: 31px;
  }

  .comission-data-table.comission-data-t2 {
    min-height: 800px;
  }

  .comission-data-table.comission-data-t2 [class*="ReactPaginateComp__ReactPaginateStyle-sc"] {
    position: sticky;
    bottom: 0;
    background: #fff;
  }

  .order_id_filter {
    justify-content: center;
  }

  .commission-cstm .export-to-xls+.groups.tabs .col-md-6.active {
    margin: 0 0px 20px !important;
    max-width: 100%;
    text-align: center;
  }

  .container.dashboard-main.commission-cstm .export-to-xls+.groups.tabs .tabs {
    padding-left: 0 !important;
  }

  .dialog_scrollClass .close_button {
    top: 6px;
  }

  .paymentform_from_savedCardComp_main_dialog .close_button {
    top: 15px;
  }

  .payment_fields .action_button button {
    margin: 0 !important;
  }

  .action_button {
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  .Level h2 {
    font-size: 18px;
  }

  /* .first_order_coupontable .coupon_list_table .coupan_list_table_main_div tr.MuiTableRow-root {
    display: contents;
  } */

  .first_order_coupontable .coupon_list_table table {
    width: 200vw;
    white-space: nowrap;
  }

  .main-invitaion table {
    width: 100%;
  }

  .main-invitaion {
    overflow-x: visible;
  }

  .main-invitaion table.comistion-table-data.dashboard-table-commsion.address-list {
    margin: 10px 0 0 !important;
  }

  .main-invitaion table.comistion-table-data.dashboard-table-commsion.address-list tbody tr td {
    padding-left: 105px !important;
  }

  .main-invitaion table.comistion-table-data.dashboard-table-commsion.address-list tbody tr td:before {
    width: 100px;
  }

  .main-cstm {
    padding: 0 15px;
    float: left;
    width: 100%;
  }

  .main-invitaion a.btn.btn-primary {
    margin-right: 0;
    max-width: 100px;
    padding: 0;
    height: 35px;
  }

  .coupon_list_table th {
    font-size: 14px;
  }

  .mainorder-detail-sec .main-qty-sec .box {
    padding-right: 0;
  }

  .main-invitaion table.comistion-table-data.dashboard-table-commsion.address-list button[aria-label="copy"] {
    margin-right: 0 !important;
  }

  .dialog_scrollClass .product_add_from_autoship_main_dialog .close_button {
    top: 14px;
  }

  .Cart_product.order-detail-sec.refund_detail_section.cstm-refund .Sub-total {
    margin: 0;
  }

  .Cart_product.order-detail-sec.refund_detail_section.cstm-refund+.row.order-cstm {
    margin-top: 10px;
    padding: 0;
  }

  .Cart_product.order-detail-sec.refund_detail_section.cstm-refund table tbody .Sub-total td+.cstmsub-total:nth-last-child(2) {
    display: none !important;
  }

  .Sub-total .cstmsub-total-sub {
    display: none;
  }

  .main_refund_section_class .refund .row.order-cstm [class*="col-md"] {
    padding: 0;
  }

  /* 
  .footer_text::after {
    top: auto;
    bottom: -65px;
  } */

  .footer_text {
    padding: 15px;
    max-width: calc(100% - 17px);
  }

  .active-block>div {
    margin-bottom: 5px;
  }

  .thnku-sec h4 {
    font-size: 20px;
    text-transform: capitalize;
  }

  .Cart_product .table-wrap table {
    border: none;
  }

  .Cart_product .table-wrap table .productDetailTable td:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }

  [class*="PayCardSection"] .main_saved_comp button {
    border: 1px solid #ddd;
    padding: 0 20px;
  }

  .header-section>.upper_lower_div {
    margin-bottom: 0;
  }

  .container.order-detail-page.order-history .order_table.address-list.order-list table.commission-table td.action-btn {
    min-width: auto;
  }

  .container.order-detail-page.order-history .order_table.address-list.order-list button {
    margin: 0 !important;
  }

  .mainorder-detail-sec.main_order_section_class .order-history.container.order-detail-page td.action-btn button {
    font-size: 12px !important;
  }

  .icon-box-cart span {
    border: 2px solid #ddd;
    font-size: 12px !important;
  }

  .container.order-detail-page.order-history .order_table.address-list.order-list table.commission-table td.action-btn {
    padding-top: 50px !important;
  }
}

/* for custom scroll */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #38528e;
}


/* 29-09-2022 */


.promoter_pop_up_main_dialog .promoter_content {
  margin-top: 30px;
}

.buttonSection .full-pack-details a {
  color: var(--blue);
  margin-bottom: 5px;
  display: block;
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  text-underline-offset: 2px;
}

.checkoutButton button[type="submit"] {
  margin-left: 10px;
}

.container.signUpReview_page .main-bx-pack-member .edit-del.d-flex {
  border-bottom: 0;
  padding-bottom: 0;
}

body.signupform-data.bg-salmon .main_div {
  padding-bottom: 30px;
}

.header-section .navbar-right li.view_cart_section,
li.wishlist_section.from_navBar_section {
  padding: 0;
}

.MuiPaper-elevation .order_details_main_dialog h2 {
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 400;
}

.MuiPaper-elevation .profile-popup span {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 0px;
  display: block;
}

.goto-profile {
  font-size: 16px;
}

.MuiPaper-elevation .goto-profile a {
  font-size: 16px;
  margin-top: 15px;
  display: inline-flex;
  min-width: 100px;
  background: var(--blue);
  border-radius: 30px;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  text-transform: capitalize;
  justify-content: center;
  transition: 0.3s ease all;
  border: 2px solid var(--blue);
}

.user-data .d-flex>div label {
  font-size: 16px;
  background: #f1f1f1;
  text-align: left;
  font-weight: 600;
  text-decoration: none;
  padding: 4px 10px;
}

.MuiPaper-elevation .goto-profile a:hover {
  background: #fff;
  color: var(--blue);
}

.user-data .d-flex {
  justify-content: space-between;
}

.user-data .d-flex>div {
  max-width: calc(100%/2 - 10px);
  width: 100%;
  flex: calc(100%/2 - 10px);
  margin-bottom: 0;
}

.user-data .form-group {
  font-size: 16px;
}

.user-data .d-flex>div label {
  font-size: 16px;
  background: var(--blue);
  text-align: left;
  font-weight: 600;
  text-decoration: none;
  padding: 4px 10px;
  color: #fff;
}

.profile-popup .user-data span {
  text-transform: capitalize;
  font-size: 18px;
  word-break: break-all;
  padding: 0 10px;
}

.image-popup img {
  max-width: 100px;
}

.profile-popup .form-group.first-name span {
  font-size: 30px;
  font-weight: 700;
}

.profile-popup .form-group:not(.first-name) span {
  color: rgb(145, 145, 146);
}

.profile-popup .form-group {
  text-align: center;
  margin-bottom: 10px;
}

.image-popup {
  text-align: center;
  margin-bottom: 20px;
}

span.popupData {
  font-size: 16px;
  text-align: center;
  padding: 10px;
  display: block;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.order_details.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.profile-popup {
  max-width: 800px;
}

.goto-profile {
  text-align: center;
}

.user-data .d-flex {
  position: relative;
  align-items: center;
  margin-bottom: 15px;
}

.add_address_from_CheckoutPage_main_dialog .container_for_load_data {
  padding-top: 15px;
}

.referral-copy input {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 7px 15px;
  margin-left: 10px;
}

.upload__image-wrapper.profile-section-now.main-profile-update {
  padding-top: 20px;
}

.upload__image-wrapper.profile-section-now.main-profile-update button {
  margin: 0 auto !important;
}

.note-mainbx {
  padding: 10px 20px 17px;
  background: #f1f1f1;
  border-radius: 5px;
}

.referral-copy button[disabled] {
  background: var(--blue) !important;
  opacity: 0.32;
  color: #fff !important;
}

.newrefshow.d-flex {
  width: 100%;
  max-width: 100%;
  align-items: center;
}

.sharekaire-heading {
  margin-top: 16px;
}

/* 
.n-mainbx {
  padding: 10px;
  background: #f1f1f1;
  border-radius: 5px;
} */

.n-mainbx .coupon-para h5 {
  font-size: 15px;
  margin: 10px 0;
}

.note-mainbx p {
  font-size: 14px;
}

.newrefshow.d-flex .referral-copy input[type="text"] {
  margin-left: 0;
  margin-right: 5px;
  max-width: 100px;
}

body .mainorder-detail-sec .container.order-detail-page .copied_ref button {
  padding: 0 !important;
  height: 38px;
  width: 38px !important;
  max-width: 100%;
  background: #fff;
  color: var(--blue);
  margin: 0;
}

.invitation-form {
  clear: both;
}

.invitation-form,
.n-mainbx.cstm-generat,
.main-invitaion {
  background: #f1f1f1;
}

.coupon-wrap.px-3 {
  padding: 0 20px 20px;
}

.n-mainbx.cstm-generat {
  clear: both;
  margin-top: 20px;
}

.n-mainbx.cstm-generat h2 {
  margin-top: 0;
  box-shadow: 6px 6px 17px -12px #3a3232;
  background: rgba(0, 53, 106, 0.92);
}

.invitation-form form.invitation-link-main {
  padding: 15px 15px;
  float: left;
  width: 100%;
}

.invitation-form {
  float: left;
  width: 100%;
  margin-top: 20px;
}

.main-cstm h2 {
  padding: 13px 15px;
  color: #fff;
  font-size: 26px;
  margin: 20px 0;
  font-weight: 600;
  box-shadow: 6px 6px 17px -12px #3a3232;
  background: rgba(0, 53, 106, 0.92);
}

.istviewsection:hover .details-list-section {
  display: block !important;
}

.add-kaire {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.add-kaire input[type="text"] {
  max-width: 140px;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #ddd;
  padding: 10px;
}

/* 18-10-2022 */
.container.checkout-main .main-qty-secc button {
  height: 40px;
  color: #000;
  border: 0 !important;
  padding: 0;
  background: #fff;
  width: 30px !important;
  border-right: 1px solid #06356a !important;
  border-left: 1px solid #06356a !important;
  border-radius: 0 !important;
  margin: 0 !important;
}

table.Cart_product .main-qty-secc #qty input {
  width: 40px;
  border: 0;
  height: 37px;
  text-align: center;
  background: #fff;
  padding-right: 0;
}

table.Cart_product .main-qty-secc #qty {
  position: relative;
  align-items: center;
  border: 1px solid var(--blue);
  border-left: 0;
  border-right: 0;
  width: max-content;
}

table.Cart_product .main-qty-secc {
  padding: 0;
  margin: 0 auto;
  width: 130px;
  justify-content: center;
}

.main-left .field-class {
  margin-bottom: 0;
}

label.form-label {
  font-weight: normal;
  font-family: var(--common-fontmd);
}

@media (max-width: 767px) {
  .container.order-detail-page h2 {
    text-align: center;
    font-size: 22px;
  }

  .add-kaire input[type="text"] {
    max-width: 100%;
  }

  .MuiPaper-elevation .profile-popup span {
    font-size: 16px;
    line-height: normal;
  }

  .MuiDialogContent-root.container_for_load_data {
    padding: 20px 10px;
  }

  .banner-product-newimage img {
    max-width: 130px;
  }

  .banner-product-newimage {
    bottom: 230px;
  }

  .fotter_section .logo img {
    /* max-width: 100%; */
  }

  .profile-popup .form-group.first-name span {
    font-size: 26px;
  }

  .image-popup {
    margin-bottom: 10px
  }

  .image-popup img {
    max-width: 80px;
  }

  .welcome-text {
    min-height: 180px;
  }

  /* 11-10-2022 */
  .main_profile_section_class .profile-img-left table tr {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .main_profile_section_class .profile-img-left table tr td {
    width: 100%;
  }

  .mainorder-detail-sec.main_profile_section_class .profile-page-main .dashboard_main_div.main_class_responsive th {
    font-size: 14px !important;
    padding: 10px 15px !important;
    width: 100%;
  }

  .mainorder-detail-sec.main_profile_section_class .profile-page-main .dashboard_main_div.main_class_responsive td {
    font-size: 14px;
    padding-top: 0;
  }

  .order_table.address-list.order-list tbody tr td[data-value="ACTIONS"]:before {
    width: 100%;
    max-width: 100%;
    padding: 10px;
    justify-content: center;
    height: 50px;
  }

  .order_table.address-list.order-list tbody tr td[data-value="ACTIONS"] {
    padding: 60px 20px 10px !important;
  }

  .leftsec>.not-check {
    padding-left: 0;
  }

  .main_profile_section_class .profile-text-right tr {
    display: flex;
    flex-wrap: wrap;
  }

  .main_profile_section_class .table-profile-details .profile-img-left {
    max-width: 100% !important;
    flex: 100%;
    width: 100%;
  }

  .main_profile_section_class .profile-text-right tr td {
    width: 100%;
  }

  .main_profile_section_class .profile-text-right tr th {
    padding: 10px 10px !important;
    background: var(--blue);
    color: #fff;
  }

  .mainorder-detail-sec.main_profile_section_class.profile-cstm-main .table-profile-details {
    gap: 10px;
  }

  .mainorder-detail-sec.main_profile_section_class.profile-cstm-main .table-profile-details td {
    width: 100%;
    padding: 10px 15px !important;
  }

  /* 11-10-2022 */
  .container.order-detail-page.order-history .order_table.address-list.order-list table.commission-table td.action-btn {
    padding-top: 60px !important;
    justify-content: center;
    flex-wrap: wrap;
  }

  .mainorder-detail-sec.main_profile_section_class .profile-page-main .dashboard_main_div.main_class_responsive th {
    font-size: 14px !important;
    padding: 10px 15px !important;
    width: 100%;
    background: var(--blue);
    color: #fff;
  }

  .main_profile_section_class .profile-img-left table {
    width: 100%;
    border-top: 1px solid #ddd;
  }

  .main_profile_section_class .profile-text-right table {
    margin: 30px auto;
    width: 100%;
    max-width: calc(100% - 30px);
    border: 1px solid #ddd;
  }

  .container.order-detail-page.order-history .order_table.address-list.order-list td.action-btn {
    gap: 5px;
  }

  .container.order-detail-page.order-history .order_table.address-list.order-list td.action-btn .title12 {
    padding-left: 0;
    max-width: unset;
    width: auto !important;
  }

  .container.order-detail-page.order-history .order_table.address-list.order-list ul.pagination {
    margin-bottom: 0 !important;
    margin-top: 10px !important;
    gap: 10px;
  }

  .main_profile_section_class .profile-img-left table tr,
  .main_profile_section_class .profile-text-right table tr {
    display: flex;
    flex-wrap: wrap;
  }

  .order_table.address-list.order-list.auto-ship-order-table tr td[data-value="ACTIONS"]:before {
    width: 100%;
    max-width: 100%;
    height: auto;
    justify-content: center;
  }

  .order_table.address-list.order-list.auto-ship-order-table tr td[data-value="ACTIONS"] {
    padding: 50px 0 10px 0 !important;
  }

  .leftsec>.not-check {
    padding-left: 0;
  }

  .main-cstm h2 {
    font-size: 18px;
  }

  .newrefshow.d-flex .referral-copy input[type="text"] {
    max-width: 60%;
    width: 100%;
  }

  .copied_ref {
    width: 100%;
  }

  .newrefshow.d-flex {
    flex-wrap: wrap;
  }

  .newrefshow.d-flex .referral-copy button {
    max-width: 100%;
    flex: 100%;
  }

  .mainorder-detail-sec .container.order-detail-page .referral-copy button {
    display: flex;
    margin-top: 20px !important;
    width: 100%;
    max-width: 100%;
  }

  .heading-top-main.right-address {
    margin-bottom: 0 !important;
  }

  .invitation-form {
    margin-bottom: 20px;
  }

  .referral-copy {
    max-width: 100%;
    flex: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .copied_ref button {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .newrefshow.d-flex span.error {
    text-align: center;
  }

  .groups.tabs.downline_custom_style {
    margin-top: 15px;
  }

  .lavel-box .Level {
    padding-bottom: 10px;
  }

  .login-pop {
    font-size: 14px;
    line-height: normal;
  }

  .cart-now-main .Cart_product tbody tr {
    margin: 0;
  }
}

@media only screen and (max-width: 575px) {
  .container.order-detail-page.order-history .order_table.address-list.order-list td.action-btn .title12 {
    width: 100% !important;
    justify-content: center;
  }

  .newrefshow.d-flex .referral-copy input[type="text"] {
    max-width: 50%;
  }
}

.magic-link-popup .MuiPaper-root {
  width: 100%;
  border-radius: 10px;
}

@media only screen and (max-width: 767px) {
  .magic-link-popup .MuiPaper-root {
    margin: 30px 15px;
  }

  span.Total,
  span.amount_paid {
    justify-content: center;
  }
}

.disclaimer {
  color: #fff;
  margin-bottom: 15px;
}

.footer-list-wrap {
  display: flex;
  max-width: 873px;
  margin: auto;
  justify-content: space-between;
}

.fotter-list ul {
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  width: auto;
}

.fotter_section .fotter-list .contact-list li span {
  color: #fff;
  display: block;
}

.fotter-list ul li a {
  border: none;
  padding: 0;
}

@media only screen and (max-width:575px) {
  .footer-list-wrap {
    flex-wrap: wrap;
  }

  .fotter-list ul {
    text-align: center;
    width: 100%;
  }
}



.MuiDialog-root .MuiPaper-root {
  max-width: 1100px;

  .popup-main {
    input {
      font-size: 14px;
    }

    .main_btn_common {
      margin: 0;
      width: 100%;
      text-transform: capitalize;
      border-radius: 50px;
    }

    .MuiDialogContent-root {
      display: flex;
      padding: 0;
    }

    .MuiDialogActions-root {
      padding: 0;
      margin: 5px 0 15px;
    }

    span.MuiTypography-root {
      font-size: 15px;
      color: #000000;
      font-family: "Graphik";
      line-height: 1.2;
    }

    .popup-descrip {
      width: 44%;
      padding: 35px;

      h2 {
        padding: 0;
        font-size: 32px;
        line-height: 35px;
        color: #000000;
        font-weight: bold;
        font-family: "Graphik-bold";
        margin-bottom: 10px;
      }

      p {
        font-size: 24px;
        color: #000;
        line-height: 1.3;
        font-family: 'Graphik';
        margin: 0;

        +p {
          font-weight: 600;
          margin: 25px 0 25px;
        }
      }

      .MuiFormControl-root {
        margin: 0 0 10px 0;

        fieldset {
          border: 1px solid #b3b3b3;
        }

        span {
          color: #000;
        }

        label {
          font-size: 20px;
          line-height: normal;
          color: #999999;
          margin: 0;
          top: -2px;
        }
      }

    }

    .img-section {
      width: 56%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .last {
      margin-top: 20px;
      display: block;
    }
  }
}

.home-all-data {
  min-height: 400px;
}

.single-page-spacing {
  min-height: 400px;
}

.date-info-subs {
  padding-right: 9px;
  padding-top: 5px;
  font-size: 17px;
}

@media (max-width:991px) {

  body.dashboard .mainorder-detail-sec,
  body.dashboard .profile-page-main,
  body.dashboard .inner-div {
    float: unset;
  }
}

@media (max-width:991px) {
  .popup-main .img-section>div {
    height: 100%;
  }

  .popup-main .img-section>div img {
    object-fit: cover;
  }

  .popup-main .popup-descrip {
    padding: 15px !important;
  }

  .popup-descrip h2 {
    font-size: 24px !important;
  }
}



@media (max-width:767px) {
  .popup-main .MuiDialogContent-root {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .popup-main .popup-descrip {
    width: 100% !important;
  }

  .popup-main .img-section {
    width: 100% !important;
  }
}

.popup-main {
  display: flex;

  .MuiDialogContent-root {
    align-items: center;
  }
}

.popup-descrip p.MuiTypography-root.MuiTypography-gutterBottom {
  margin-bottom: 10px !important;
}

.autoship_radio_container .price-section {
  padding-right: 30px !important;
}

.MuiTooltip-tooltip {
  font-size: 20px !important;
}

.popup-main legend.css-1br1jja {
  display: none;
}

.popup-main fieldset {
  top: 0 !important;
}


.popup-main .img-section {
  height: 100%;
}

.popup-main .img-section img {
  display: block;
  height: 100%;
}

.popup-main .img-section>div {
  height: 100%;
}

input.MuiOutlinedInput-input.MuiInputBase-input.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 16px;
}

.css-1o2jng6-MuiOutlinedInput-notchedOutline {
  inset: 0;
}

.main-friend-refer {
  display: grid;
  width: 100%;
  max-width: 408px;
  margin-bottom: 15px;
}